import React, { useEffect } from "react";
import usePriorityApi from "../hooks/UsePriorityApi";
import { bucketListAtom, currentSelectedPriorityAtom } from "../recoil/priority";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Card, Grid, IconButton, Menu, MenuItem, Stack, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import ClosePriorityDialog from "./ClosePriorityDialog";
import { ListPriorityModel } from "../recoil/priority";
import DragAndDrop from "./DragAndDrop";
import PriorityDetailPage from "./PriorityDetailPage";
import AlertComponent from "./AlertComponent";
import usePriorityUtils from "../utils/PriorityUtils";
import { useRecoilValue, useRecoilState } from "recoil";
import CreatePriorityButton from "./CreatePriorityButton";

interface BucketPrioritiesProps {
    selectedPriorityId?: number,
    client: any
}

const BucketList: React.FC<BucketPrioritiesProps> = (props) => {
    const bucketListPriorities = useRecoilValue(bucketListAtom);
    console.log("bucketListPriorities length:", bucketListPriorities.length)
    const {
        onBucketListDragAndDropped,
    } = usePriorityApi();

    const [currentSelectedPriority, setCurrentSelectedPriority] = useRecoilState(currentSelectedPriorityAtom);

    const {setAlertOpen, onSuccess, onPlayPauseClick, onBuzzerClick, 
        onTaskDoneClick, onMoveToBucketListClick, onMoveToYourPrioritiesClick, closedPriority, setClosedPriority,
        alertOpen, alertSeverity, alertText } = usePriorityUtils();

    const theme = useTheme();
    const isSizeSmallOrAbove = useMediaQuery(theme.breakpoints.up("sm"));

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
      };

    useEffect(() => {
        console.log("useEffect from BucketList.tsx, bucket list is: " + JSON.stringify(bucketListPriorities));
        if (props.selectedPriorityId !== currentSelectedPriority?.id) {
            const _selectedPriority = bucketListPriorities.find((p) => p.id == props.selectedPriorityId);
            _selectedPriority && onPrioritySelect(_selectedPriority);
        };
    }, [props, bucketListPriorities]);

    const onPrioritySelect = (priority: ListPriorityModel) => {
        setCurrentSelectedPriority((currentPriority) => (currentPriority?.id === priority.id ? null : priority));
    };

    return (
        <>
            <Box px={{ sm: 2, md: 4 }} py={4}>
            <CreatePriorityButton/>
                <DragAndDrop
                    droppableId="BucketList"
                    onDragEnd={onBucketListDragAndDropped}
                    onDragAndDropSuccess={onSuccess}
                    items={bucketListPriorities}
                    renderItem={(priority: ListPriorityModel, index: number) => {
                        // const isPaused = priority.state === "Active" ? "#4caf50" : "#ff1744";
                        // const isTargetDate = moment(priority.target_date).isBefore(new Date()) ? "#ff1744" : isPaused;
                        const isPaused = priority.state === "Active" ? "#c2f2c3" : "#f2c2cb";
                        const isTargetDate = moment(priority.target_date).isBefore(new Date()) ? "#f2c2cb" : isPaused;
                        return (
                            <Grid key={priority.title} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item md={8} xs={12} sx={{ padding: "0!important" }}>
                                    <Card
                                        key={priority.title}
                                        onClick={() => onPrioritySelect(priority)}
                                        sx={{ marginBottom: "12px", display: "flex" }}
                                    >
                                        <Stack
                                            direction={"column"}
                                            flexGrow={1}
                                            sx={{
                                                padding: "10px 20px",
                                            }}
                                        >
                                            <Stack
                                                justifyContent="space-around"
                                                direction={"row"}
                                                alignItems={"center"}
                                            >
                                                <div>
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: isTargetDate,
                                                            marginRight: 2,
                                                            width: "35px",
                                                            height: "35px",
                                                            fontSize: "18px",
                                                            color: "black",
                                                            fontWeight: "600"
                                                        }}
                                                        style={{ alignItems: "center" }}
                                                    >
                                                        {index + 1}
                                                    </Avatar>
                                                </div>
                                                <Typography
                                                    variant="h6"
                                                    align="left"
                                                    sx={{
                                                        width: "min-content",
                                                        flexGrow: 1,
                                                        margin: "0 2",
                                                    }}
                                                >
                                                    {priority.title}
                                                </Typography>
                                                {isSizeSmallOrAbove && (
                                                    <Stack direction={"column"} ml={2} alignItems={"center"}>
                                                        <PriorityDate priority={priority} />
                                                        <PriorityActions
                                                            priority={priority}
                                                            onMoveToYourPrioritiesClick={onMoveToYourPrioritiesClick}
                                                        />
                                                    </Stack>
                                                )}
                                            </Stack>
                                            {!isSizeSmallOrAbove && (
                                                <Stack direction={"column"} ml={2} mt={2} alignItems={"center"}>
                                                    <PriorityDate priority={priority} />
                                                    <PriorityActions
                                                        priority={priority}
                                                        onMoveToYourPrioritiesClick={onMoveToYourPrioritiesClick}
                                                    />
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Card>
                                </Grid>

                                <Grid item md={4} xs={12} />
                            </Grid>
                        );
                    }}
                />
                {closedPriority && (
                    <ClosePriorityDialog priority={closedPriority} />
                )}
                {currentSelectedPriority && (
                    <PriorityDetailPage
                        // index={bucketListPriorities.indexOf(currentSelectedPriority)}
                        index={currentSelectedPriority.priority_order - 6}
                        variant = 'full'
                        listAtom={bucketListAtom}
                        priorityId={currentSelectedPriority.id}
                        onCloseDialog={() => setCurrentSelectedPriority(null)}
                        onPlayPauseClick={onPlayPauseClick}
                        onBuzzerClick={onBuzzerClick}
                        onMoveToYourPrioritiesClick={onMoveToYourPrioritiesClick}
                        onMoveToBucketListPrioritiesClick={onMoveToBucketListClick}
                        onClosePriorityClick={onTaskDoneClick}
                    />
                )}
            </Box>
            <AlertComponent open={alertOpen} handleClose={handleAlertClose} severity={alertSeverity} alertText={alertText} />
        </>
    );
}

interface PriorityDateProps {
    priority: ListPriorityModel;
}

const PriorityDate: React.FC<PriorityDateProps> = ({ priority }) => {
    const date = moment(priority.target_date);
    const formattedDate = date.format("MMM DD, YYYY");
    const relativeDate = date.fromNow();
    const dateUiText = `${formattedDate} (${relativeDate})`;

    return (
        <Typography
            noWrap
            sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: "gray",
            }}
        >
            {dateUiText}
        </Typography>
    );
};

interface PriorityActionsProps {
    priority: ListPriorityModel;
    onMoveToYourPrioritiesClick: (event: React.MouseEvent, priority: ListPriorityModel) => void;
}

const PriorityActions: React.FC<PriorityActionsProps> = ({ priority, onMoveToYourPrioritiesClick }) => {
    const [moreAnchorElement, setMoreAnchorElement] = React.useState<null | HTMLElement>(null);
    const isMoreOpen = Boolean(moreAnchorElement);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMoreAnchorElement(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        setMoreAnchorElement(null);
    };

    const handleMoreItemClick = (event: React.MouseEvent, index: number) => {
        event.stopPropagation();
        if (index === 0) onMoveToYourPrioritiesClick(event, priority);
        handleClose(event);
    };

    return (
        <Stack direction={"row"}>
            <Tooltip title="More options">
            <IconButton onClick={handleClick}>
                <MoreVertIcon style={{ fontSize: 32 }} />
            </IconButton>
            </Tooltip>
            <Menu
                anchorEl={moreAnchorElement}
                open={isMoreOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem onClick={(e) => handleMoreItemClick(e, 0)}>Move to Your Priorities</MenuItem>
            </Menu>
        </Stack>
    );
};

export default BucketList;
