import { z } from "zod";
import * as Either from "fp-ts/lib/Either";

export const validateZodSchema = <T>(schema: z.ZodType<T>, data: T): Either.Either<z.ZodFormattedError<T>, T> => {
    const parseResult = schema.safeParse(data);
    if (parseResult.success) return Either.right(parseResult.data);

    const formattedErrors = parseResult.error.format();
    return Either.left(formattedErrors);
};
