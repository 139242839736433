import { useRecoilState, useSetRecoilState } from "recoil";
import {
    changePriorityOrder,
    changePriorityType,
    getBucketListPriorities,
    getHistoricalPriorities,
    getPrioritiesByKeyword,
    getPriorityComments,
    getYourPriorities,
    postPriorityComment,
    postYourPriority,
    putPriorityClosure,
    putStateChange,
    getNeti5edSchedule
} from "../api/Priority";
import { ClosingPriorityModel } from "../recoil/priority";
import { CreatePriorityModel } from "../recoil/priority";
import { ListPriorityModel } from "../recoil/priority";
import { Auth } from "aws-amplify";
import { getPriorityRequests, putPriorityRequests } from "../api/Requests";
import { useState } from "react";
import { yourPrioritiesListAtom, bucketListAtom, historicalPrioritiesAtom, priorityRequestsAtom, 
    priorityCommentsAtom, searchPrioritiesAtom, neti5edSchedulesAtom } from "../recoil/priority";

export interface PriorityApiModel {
    priorities: CreatePriorityModel[];
    createPriority: (priority: CreatePriorityModel) => void;
    addDummy: () => void;
}

const usePriorityApi = () => {
    const [yourPriorities, setYourPriorities] = useRecoilState(yourPrioritiesListAtom);
    const [bucketListPriorities, setBucketListPriorities] = useRecoilState(bucketListAtom);
    const setHistoricalPriorities  = useSetRecoilState(historicalPrioritiesAtom);
    const setPriorityRequests = useSetRecoilState(priorityRequestsAtom);
    const setPriorityComments = useSetRecoilState(priorityCommentsAtom);
    const [searchPriorities, setSearchPriorities] = useRecoilState(searchPrioritiesAtom);
    const [neti5edSchedule, setNeti5edSchedule] = useRecoilState(neti5edSchedulesAtom);

    const invokeNeti5Schedule = async (numWeeks: number) => {
        console.log("Invoking Neti5 Schedule API with num weeks as:" + numWeeks);
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getNeti5edSchedule(token, currentUserId, numWeeks, setNeti5edSchedule);
    };

    const fetchYourPriorities = async (onSuccess: () => void) => {
        console.log("Fetching Your Priorities from API");
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        console.log("token from fetch priorities:", token);
        getYourPriorities(token, currentUserId, setYourPriorities, onSuccess);
    };

    const fetchBucketListPriorities = async () => {
        console.log("Fetching Bucket List from API");
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getBucketListPriorities(token, currentUserId, setBucketListPriorities);
    };

    const fetchHistoricalPriorities = async () => {
        console.log("Fetching Historical Priorities from API");
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getHistoricalPriorities(token, currentUserId, setHistoricalPriorities);
    };

    const fetchPriorityRequests = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;

        getPriorityRequests(token, currentUserId, setPriorityRequests);
    };

    const fetchPriorityComments = async (priorityId: number) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;

        getPriorityComments(token, currentUserId, priorityId, setPriorityComments);
    };

    const modifyPriorityRequestStatus = async (request) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;

        putPriorityRequests(request, token, currentUserId);
        getPriorityRequests(token, currentUserId, setPriorityRequests);
        getBucketListPriorities(token, currentUserId, setBucketListPriorities);
    };

    const createPriority = async (priority: CreatePriorityModel, onSuccess) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        priority.creator = currentUserId;
        postYourPriority(token, currentUserId, priority, setYourPriorities, setBucketListPriorities, onSuccess);
    };

    const addComment = async (commentText: string, priority_id: number) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        postPriorityComment(token, currentUserId, priority_id, commentText, setPriorityComments);
    };

    const closePriority = async (formData: ClosingPriorityModel, onSuccess, priority: ListPriorityModel) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        putPriorityClosure(formData, token, currentUserId, setYourPriorities, setHistoricalPriorities, onSuccess, priority);
    };

    const moveToYourPriorities = async (priority: ListPriorityModel, onSuccess, target_users: Array<string | undefined>) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        changePriorityType(token, currentUserId, priority, setYourPriorities, setBucketListPriorities, onSuccess, target_users);
    };

    const moveToBucketListPriorities = async (priority: ListPriorityModel, onSuccess, target_users: Array<string | undefined>) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        changePriorityType(token, currentUserId, priority, setYourPriorities, setBucketListPriorities, onSuccess, target_users);
    };

    const toggleProgress = async (priority: ListPriorityModel, onSuccess, target_users: Array<string | undefined>) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        putStateChange(priority, token, currentUserId, setYourPriorities, onSuccess, target_users);
    };

    const onYourPriorityDragAndDropped = async (from: number, to: number, onSuccess: (user: string) => void) => {
        const items = Array.from(yourPriorities);
        const [removedItem] = items.splice(from, 1);
        console.log("priority dragged:", removedItem?.id);
        console.log("new_priority_order:", to)
        items.splice(to, 0, removedItem!);
        setYourPriorities(items);
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        changePriorityOrder(token, currentUserId, removedItem, to+1, onSuccess);
    };

    const onBucketListDragAndDropped = async (from: number, to: number, onSuccess) => {
        const items = Array.from(bucketListPriorities);
        const [removedItem] = items.splice(from, 1);
        items.splice(to, 0, removedItem!);
        setBucketListPriorities(items);
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        changePriorityOrder(token, currentUserId, removedItem, to+6, onSuccess);
    };

    const [isSearchLoading, setIsSearchLoading] = useState(false)

    const fetchPrioritiesByKeyword = async (searchTerm: string) => {
        setIsSearchLoading(true);
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getPrioritiesByKeyword(token, searchTerm, setSearchPriorities, setIsSearchLoading);
        // setIsSearchLoading(false);
    };

    return {
        yourPriorities,
        bucketListPriorities,
        setYourPriorities,
        setBucketListPriorities,
        invokeNeti5Schedule,
        fetchYourPriorities,
        fetchBucketListPriorities,
        fetchHistoricalPriorities,
        fetchPriorityRequests,
        modifyPriorityRequestStatus,
        moveToYourPriorities,
        moveToBucketListPriorities,
        createPriority,
        closePriority,
        toggleProgress,
        onYourPriorityDragAndDropped,
        onBucketListDragAndDropped,
        addComment,
        setPriorityComments,
        fetchPriorityComments,
        neti5edSchedule,
        searchPriorities,
        setSearchPriorities,
        fetchPrioritiesByKeyword,
        isSearchLoading,
    };
};

export default usePriorityApi;
