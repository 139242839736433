import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Grid, Typography, Button, IconButton, useTheme, useMediaQuery, Divider } from "@mui/material";
import MyResponsiveBar from "../components/charts/MyResponsiveBar";
import MyResponsiveLine from "../components/charts/MyResponsiveLine";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBox from "../components/charts/StatBox";
import TrafficIcon from "@mui/icons-material/Traffic";
import ProgressCircle from "../components/charts/ProgressCircle";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import SnoozeIcon from '@mui/icons-material/Snooze';
import { tokens } from "../theme";
import MyResponsiveBar2 from "../components/charts/MyResponsiveBar2";


interface InsightsPageProps {
    
    }

export function InsightsPage(props: InsightsPageProps) {

    const displayText = "Insights"

    return (
        <Box width={"100%"} px={4} py={4} >
            <Toolbar />
            <Typography variant="h5" mb={4} sx={{ fontWeight: "bold" }}>
            {displayText}
            </Typography>
            <Box display={"grid"} gridTemplateColumns={"repeat(12, 1fr)"} gridAutoRows={"300px"} gap={"20px"}>
                {/* <Grid container spacing={1} direction="row"> */}
                    {/* <Grid item > */}
            <Box 
                gridColumn="span 3" 
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
            <MyResponsiveBar/>
            </Box>
            <Box 
                gridColumn="span 6"
                // display="flex"
                // alignItems="center"
                // justifyContent="center"
            >
                <Box>
                <Typography>
                    Line Chart
                </Typography>
                </Box>
                <Box>
            <MyResponsiveLine/>
            </Box>
            </Box>
            <Box 
                gridColumn="span 3" 
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
            <MyResponsiveBar/>
            </Box>
            {/* </Grid> */}
            {/* </Grid> */}
            </Box>
        </Box>
    );
}



export const mockTransactions = [
    {
      txId: "01e4dsa",
      user: "johndoe",
      date: "2021-09-01",
      cost: "43.95",
    },
    {
      txId: "0315dsaa",
      user: "jackdower",
      date: "2022-04-01",
      cost: "133.45",
    },
    {
      txId: "01e4dsa",
      user: "aberdohnny",
      date: "2021-09-01",
      cost: "43.95",
    },
    {
      txId: "51034szv",
      user: "goodmanave",
      date: "2022-11-05",
      cost: "200.95",
    },
    {
      txId: "0a123sb",
      user: "stevebower",
      date: "2022-11-02",
      cost: "13.55",
    },
    {
      txId: "01e4dsa",
      user: "aberdohnny",
      date: "2021-09-01",
      cost: "43.95",
    },
    {
      txId: "120s51a",
      user: "wootzifer",
      date: "2019-04-15",
      cost: "24.20",
    },
    {
      txId: "0315dsaa",
      user: "jackdower",
      date: "2022-04-01",
      cost: "133.45",
    },
  ];

export const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    return (
      <Box m="20px">
        <Toolbar />
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" mb={4} sx={{ fontWeight: "bold" }}>
            INSIGHTS
          </Typography>
  
          {/* <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </Box> */}
        </Box>
  
        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* ROW 1 */}
          <Box gridColumn={isSmScreen?"span 6":"span 3"} 
            bgcolor={colors.primary[400]} borderRadius={"10px"}
            display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
            <StatBox
              title="26"
              subtitle="Priorities"
              progress="0.75"
              increase="6 months"
              icon={
                <FormatListNumberedIcon
                  sx={{ 
                    color: colors.greenAccent[600], 
                    fontSize: "52px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn={isSmScreen?"span 6":"span 3"}
            bgcolor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={"10px"}
          >
            <StatBox
              title="4"
              subtitle="Org initiatives"
              progress="0.50"
              increase="6 months"
              icon={
                <BusinessOutlinedIcon
                  sx={{ 
                    color: colors.greenAccent[600], 
                    fontSize: "52px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn = {isSmScreen?"span 6":"span 3"}
            bgcolor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={"10px"}
          >
            <StatBox
              title="17"
              subtitle="Collaborations"
              progress="0.30"
              increase="6 months"
              icon={
                <PersonAddIcon
                  sx={{ 
                    color: colors.greenAccent[600], 
                    fontSize: "52px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn={isSmScreen?"span 6":"span 3"}
            bgcolor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={"10px"}
          >
            <StatBox
              title="15"
              subtitle="Reschedules"
              progress="0.80"
              increase="6 months"
              icon={
                <SnoozeIcon
                  sx={{ 
                    color: colors.greenAccent[600], 
                    fontSize: "52px" }}
                />
              }
            />
          </Box>
  
          {/* ROW 2 */}
          <Box
            gridColumn={isSmScreen?"span 12":"span 12"}
            gridRow="span 2"
            bgcolor={colors.primary[400]}
            borderRadius={"10px"}
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Priority frequency across themes
                </Typography>
                {/* <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  $59,342.32
                </Typography> */}
              </Box>
              {/* <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", 
                    color: colors.greenAccent[500] 
                }}
                  />
                </IconButton>
              </Box> */}
            </Box>
            <Box height="250px" m="0 0 0 0">
              {/* <LineChart isDashboard={true} /> */}
              <MyResponsiveLine/>
            </Box>
          </Box>
          {/* <Box
            gridColumn="span 4"
            gridRow="span 2"
            bgcolor={colors.primary[400]}
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            //   borderBottom={`4px solid ${colors.primary[500]}`}
            //   colors={colors.grey[100]}
            //   p="15px"
            >
              <Typography 
                color={colors.grey[100]} 
                variant="h5" fontWeight="600">
                Recent Transactions
              </Typography>
            </Box>
            {mockTransactions.map((transaction, i) => (
              <Box
                key={`${transaction.txId}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction.txId}
                  </Typography>
                  <Typography 
                    color={colors.grey[100]}
                    >
                    {transaction.user}
                  </Typography>
                </Box>
                <Box 
                    // color={colors.grey[100]}
                    >{transaction.date}</Box>
                <Box
                //   backgroundColor={colors.greenAccent[500]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  ${transaction.cost}
                </Box>
              </Box>
            ))}
          </Box> */}
  
          {/* ROW 3 */}
          <Box
            gridColumn={isSmScreen?"span 12":"span 4"}
            gridRow="span 2"
            bgcolor={colors.primary[400]}
            p="30px"
            borderRadius={"10px"}
          >
            <Typography variant="h5" fontWeight="600">
              Priority completion rate
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="35px"
            >
              <ProgressCircle size="125" />
              <Typography
                variant="h5"
                color={colors.greenAccent[500]}
                sx={{ mt: "15px" }}
              >
                75%
              </Typography>
              {/* <Typography>Includes extra misc expenditures and costs</Typography> */}
            </Box>
          </Box>
          <Box
            gridColumn={isSmScreen?"span 12":"span 4"}
            gridRow="span 2"
            bgcolor={colors.primary[400]}
            borderRadius={"10px"}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              Discarded priorities
            </Typography>
            <Box height="250px" mt="-10px">
              {/* <BarChart isDashboard={true} /> */}
              <MyResponsiveBar/>
            </Box>
          </Box>
          <Box
            gridColumn={isSmScreen?"span 12":"span 4"}
            gridRow="span 2"
            bgcolor={colors.primary[400]}
            borderRadius={"10px"}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              Delayed priorities
            </Typography>
            <Box height="250px" mt="-10px">
              {/* <BarChart isDashboard={true} /> */}
              <MyResponsiveBar2/>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default Dashboard;
