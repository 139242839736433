import { atom } from "recoil";
import { NotificationModel } from "./model";

export const notificationsAtom = atom<NotificationModel[]>({
    key: "notificationsAtom",
    default: [],
});

export const allNotificationsAtom = atom<NotificationModel[]>({
    key: "allNotificationsAtom",
    default: []
    });