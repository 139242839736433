import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Toolbar, Typography } from "@mui/material";
import useCreatePriorityForm from "../hooks/UserCreatePriorityForm";
import * as Option from "fp-ts/Option";
import { CreatePriorityModel, yourPrioritiesListAtom } from "../recoil/priority";
import usePriorityApi from "../hooks/UsePriorityApi";
import useTopicsApi from "../hooks/UseTopicsApi";
import CreatePriorityLabels from "./CreatePriorityLabels";
import { CreatePriorityLabelModel } from "../recoil/topic";
import * as Either from "fp-ts/Either";
import MentionInput from "./mentioninput/MentionInput";
import FormInputItemHeader from "./FormInputItemHeader";
import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import UseWebocketConnection from "../utils/WebsocketUtils";
import AlertComponent from "./AlertComponent";
import { useRecoilValue } from "recoil";

interface CreatePriorityProps {
    currentUserId: string;
    username: string;
    client: any;
    // turn off type checking
    signOut: (params?: any) => any;
}

function CreatePrior(props: CreatePriorityProps) {
    const { data, errors, events, priorityTypeItems, priorityScopeItems, 
        levelOfEffortItems } = useCreatePriorityForm();

    const { createPriority } = usePriorityApi();

    const  yourPriorities = useRecoilValue(yourPrioritiesListAtom)

    const { createPriorityLabels, onAddLabel } = useTopicsApi();

    const navigate = useNavigate();

    const {sendNotifications} = UseWebocketConnection();

    const [alertOpen, setAlertOpen] = useState(false);

    const [alertSeverity, setAlertSeverity] = useState('success');

    const _onAddLabel = (label: CreatePriorityLabelModel) => {
        const oldSelectedlabels = data.labels
        console.log("Old Selected labels: " + JSON.stringify(oldSelectedlabels));
        Either.fold<string, CreatePriorityLabelModel, CreatePriorityLabelModel | void>(
            (error) => console.log("createdLabel failed", error),
            (createdLabel) => {
                console.log("createdLabel", createdLabel);
                data.labels = oldSelectedlabels;
                console.log("Old Selected labels on creation: " + JSON.stringify(oldSelectedlabels));
                console.log("New Selected labels: " + JSON.stringify(data.labels));
                events.onLabelChanged(createdLabel);
            }
        )(onAddLabel(label));
    };

    const onReset = async() => {
        setAlertSeverity('info');
        setAlertOpen(true);
        events.resetForm();
    }

    const onSubmit = async () => {

        console.log("submit button was clicked!");
        console.log("data is:", data);
        Option.fold<CreatePriorityModel, void>(
            () => console.log("no data"),
            (data) =>
                {   
                    (data.priority_type==='Main Priority' && yourPriorities.length === 5) ? setAlertSeverity('error'): setAlertSeverity('success');
                    setAlertOpen(true);
                    createPriority(data, () => {
                        sendNotifications(props.client, data.usertags);
                        navigate("/");
                    });
                }
        )(events.validate());
    };

    const alertText = {'success': 'Submitted successfully!',
    'info': 'Inputs were reset', 'error':'Maximum number of main priorities reached'}

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setAlertOpen(false);
      };

    useEffect(() => {
        console.log("useEffect from CreatePrior.tsx");
    }, []);

    return (
        <Suspense fallback={<div>Loading components...</div>}>
            <Box width={"100%"} px={4} py={4}>
                <Toolbar />
                <Grid container spacing={4} direction="row">
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h5" mb={4} sx={{ fontWeight: "bold" }}>
                            Create Priority
                        </Typography>
                        <Grid container rowSpacing={3}>
                            <Grid item xs={12}>
                                <FormInputItemHeader label={"Title"} fontWeight="bold" marginBottom={2} />
                                <MentionInput
                                    placeholder="a little summary"
                                    value={data.title.html}
                                    error={errors.titleError}
                                    onChange={events.onTitleChange}
                                    characterCount={150}
                                    tagUsers={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputItemHeader label={"Description"} fontWeight="bold" marginBottom={2} />
                                <MentionInput
                                    multipleLine
                                    placeholder="describe your priority"
                                    value={data.description.html}
                                    error={errors.descriptionError}
                                    onChange={events.onDescriptionChange}
                                    characterCount={600}
                                    tagUsers={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputItemHeader label={"Date"} fontWeight="bold" marginBottom={2} />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        toolbarPlaceholder="Date"
                                        value={data.date}
                                        onChange={events.onDateChange}
                                        disablePast
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{ display: "flex" }}
                                                error={errors.dateError !== null}
                                                helperText={errors.dateError}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputItemHeader label={"Labels"} fontWeight="bold" marginBottom={2} />
                                <CreatePriorityLabels
                                    labels={createPriorityLabels}
                                    selectedIds={data.labels}
                                    error={errors.labelsError}
                                    onLabelSelected={events.onLabelChanged}
                                    onAddLabel={_onAddLabel}
                                    rowSpacing={1}
                                    columnSpacing={1}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormInputItemHeader label={"Priority type"} fontWeight="bold" marginBottom={2} />
                                <PriorityTypeSection
                                    items={priorityTypeItems}
                                    defaultItem={data.type}
                                    onItemSelected={events.onTypeChanged}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormInputItemHeader label={"Priority scope"} fontWeight="bold" marginBottom={2} />
                                <PriorityTypeSection
                                    items={priorityScopeItems}
                                    defaultItem={data.scope}
                                    onItemSelected={events.onScopeChanged}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputItemHeader label={"Level of Effort"} fontWeight="bold" marginBottom={2} />
                                <PriorityTypeSection
                                    items={levelOfEffortItems}
                                    defaultItem={data.levelOfEffort}
                                    onItemSelected={events.onLevelOfEffortChanged}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} mt={4} justifyContent={"end"}>
                            <Grid item>
                                <Button
                                    onClick={onReset}
                                    variant="outlined"
                                    disableElevation
                                    sx={{ padding: "4px 18px", fontSize: "0.8rem" }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={onSubmit}
                                    variant="contained"
                                    disableElevation
                                    sx={{ padding: "4.5px 18px", fontSize: "0.8rem"}}
                                >
                                    Save
                                </Button>
                                <AlertComponent open={alertOpen} handleClose={handleAlertClose} severity={alertSeverity} alertText={alertText}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} />
                </Grid>
            </Box>
        </Suspense>
    );
}

interface PriorityTypeSectionProps {
    items: string[];
    defaultItem: string;
    onItemSelected: (selectedItem: string) => void;
}

export const PriorityTypeSection: React.FC<PriorityTypeSectionProps> = (props) => {
    return (
        <Grid container>
            <RadioGroup row value={props.defaultItem} onChange={(_, value) => props.onItemSelected(value)}>
                {props.items.map((item) => (
                    <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
                ))}
            </RadioGroup>
        </Grid>
    );
};

interface LowMediumHighSectionProps {
    items: string[];
    defaultItem: string;
    onItemSelected: (selectedItem: string) => void;
}

export const LevelOfEffortSection: React.FC<LowMediumHighSectionProps> = (props) => {
    return (
        <Grid container>
            <RadioGroup row value={props.defaultItem} onChange={(_, value) => props.onItemSelected(value)}>
                {props.items.map((item) => (
                    <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
                ))}
            </RadioGroup>
        </Grid>
    );
};

export default CreatePrior;
