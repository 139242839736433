import Mention, { MentionOptions } from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { EditorContent, ReactRenderer, useEditor } from "@tiptap/react";
import React, { useEffect, useMemo, useState } from "react";
import { MentionList } from "./MentionList";
import "./Mention.css";
import Placeholder from "@tiptap/extension-placeholder";
import { Box, Stack, Typography } from "@mui/material";
import { AnyExtension, Node } from "@tiptap/core";
import { SuggestionOptions } from "@tiptap/suggestion";
import { UserTagModel } from "../../recoil/user";
import MentionOutput from "./MentionOutput";
import Document from "@tiptap/extension-document";

interface Props {
    value: string;
    error: string | null;
    onChange: (output: MentionOutput) => void;
    characterCount: number;
    tagUsers: boolean;
    placeholder?: string;
    multipleLine?: boolean;
}

const OneLiner = Node.create({
    name: "oneLiner",
    topNode: true,
    content: "block",
});

const MentionInput: React.FC<Props> = ({ value, error, onChange, characterCount, tagUsers, ...defaultProps }) => {
    const { placeholder = "", multipleLine = false } = defaultProps;

    const [valueCount, setValueCount] = useState<number>(0);

    const extensions = useMemo<AnyExtension[]>(() => {
        const defaults = [
            Document,
            Paragraph,
            Text,
            Placeholder.configure({
                emptyEditorClass: "is-editor-empty",
                placeholder: placeholder,
            }),
            tagUsers ? Mention.configure(mentionConfig) : Mention.configure(),
        ];
        const prefixes = multipleLine ? [] : [OneLiner];
        return [...prefixes, ...defaults];
    }, []);

    const editor = useEditor({
        editorProps: { attributes: { class: `editor ${multipleLine ? "mention-multiple" : ""}` } },
        extensions: extensions,
        content: value,
        onCreate: (events) => {
            setValueCount(events.editor.getText().length);
        },
        onUpdate: (events) => {
            const output: MentionOutput = {
                content: events.editor.getText(),
                html: events.editor.getHTML(),
            };
            setValueCount(output.content.length);
            onChange(output);
        },
    });

    useEffect(() => {
        console.log("useEffect from MentionInput.tsx");
        if (value === "") {
            editor?.commands.setContent("");
            setValueCount(0);
        }
    }, [value]);

    return (
        <Stack>
            <EditorContent editor={editor} />
            <Stack direction={"row"}>
                <Box flex={1}>
                    {error && (
                        <Typography color="error" fontSize="0.75rem" sx={{ margin: "8px 14px 0px 14px" }}>
                            {error}
                        </Typography>
                    )}
                </Box>
                {editor && valueCount != 0 && (
                    <Typography variant="caption" fontSize="0.75rem" sx={{ margin: "8px 14px 0px 14px" }}>
                        {valueCount}/{characterCount}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};

const mentionConfig: Partial<MentionOptions> = {
    HTMLAttributes: {
        class: "mention",
    },
    renderLabel({ node }) {
        console.log(node.attrs.label);
        return `${node.attrs.label ?? node.attrs.id}`;
    },
    suggestion: {
        render: () => {
            var component: ReactRenderer<any>;

            return {
                onStart: (props) => {
                    component = new ReactRenderer(MentionList, {
                        props,
                        editor: props.editor,
                    });
                },

                onUpdate(props) {
                    component.updateProps(props);
                },

                onKeyDown(props) {
                    if (props.event.key === "Escape") return true;

                    return component.ref?.onKeyDown(props);
                },

                onExit() {
                    component.destroy();
                },
            };
        },
    } as Omit<SuggestionOptions<UserTagModel>, "editor">,
};

export default MentionInput;
