import { useEffect } from "react";
import { Grid } from "@mui/material";
import usePriorityApi from "../hooks/UsePriorityApi";
import CommentCard from "./CommentCard";
import { useRecoilValue } from "recoil";
import { priorityCommentsAtom } from "../recoil/priority";

interface ShowCommentsProps {
    priorityId: number;
}

const ShowCommentsComponent = (props: ShowCommentsProps) => {
    const priorityComments = useRecoilValue(priorityCommentsAtom);
    const { fetchPriorityComments } = usePriorityApi();
    const comments = priorityComments ? priorityComments[props.priorityId] : [];

    useEffect(() => {
        console.log("useEffect from ShowCommentsComponent");
        fetchPriorityComments(props.priorityId);
    }, [])

    return (
        <div>
            <Grid container spacing={0} sx={{ mt: 1 }} direction="row" justifyContent="center" alignItems="center">
                {
                    comments && comments.map(priorityComment =>
                        <CommentCard key={priorityComment.id} id={priorityComment.id}
                            comment_text={priorityComment.comment_text}
                            creation_time={priorityComment.creation_time}
                            creator={priorityComment.creator} />
                    )
                }
            </Grid>
        </div >
    );
}

export default ShowCommentsComponent
