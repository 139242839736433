import { ButtonBase, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { CreatePriorityLabelModel } from "../recoil/topic";
import CreatePriorityAddLabelDialog from "./CreatePriorityAddLabelDialog";
import { TopicMapModel } from "../recoil/priority";
import PriorityLabelSingle from "./PriorityLabelSingle";
import { getLabelColorForIndex } from "../utils/PriorityUtils";

const CORNER_RADIUS = 4;
const VERTICAL_SPACING = 4;
const MAX_ALLOWED_LABELS = 10;

interface Props {
    labels: CreatePriorityLabelModel[];
    selectedIds: TopicMapModel[];
    error: string | null;
    onLabelSelected: (label: CreatePriorityLabelModel) => void;
    onAddLabel: (label: CreatePriorityLabelModel) => void;
    rowSpacing: number;
    columnSpacing: number;
}

const CreatePriorityLabels: React.FC<Props> = (props) => {
    const { labels, rowSpacing, columnSpacing } = props;

    const [isAddDialogOpen, setIsAddDialogOpen] = React.useState<boolean>(false);

    return (
        <Stack>
            <CreatePriorityAddLabelDialog
                open={isAddDialogOpen}
                onClose={() => setIsAddDialogOpen(false)}
                onAddLabel={props.onAddLabel}
            />
            <Grid container rowSpacing={rowSpacing} columnSpacing={columnSpacing}>
                {labels && Array.isArray(labels) ?
                    labels.map((label, index) => {
                        var isSelected = false;
                        props.selectedIds.map((p) => {
                            isSelected = p.topic === label.id ? true : isSelected;
                        });
                        return (
                            <Grid item key={label.topic_name}>
                                <PriorityLabelSingle
                                    label={label}
                                    isSelected={isSelected}
                                    onLabelSelected={props.onLabelSelected}
                                    backgroundColor={getLabelColorForIndex(index)}
                                />
                            </Grid>
                        );
                    })
                    : null
                }
                {labels.length < MAX_ALLOWED_LABELS && (
                    <Grid item>
                        <ButtonBase
                            onClick={() => setIsAddDialogOpen(true)}
                            style={{
                                background: "#e8e8e8",
                                color: "#808080",
                                borderRadius: `${CORNER_RADIUS}px`,
                                padding: `${VERTICAL_SPACING}px 4px`,
                            }}
                        >
                            <AddIcon />
                        </ButtonBase>
                    </Grid>
                )}
            </Grid>
            {props.error && (
                <Typography color="error" fontSize="0.75rem" sx={{ margin: "8px 14px 0px 14px" }}>
                    {props.error}
                </Typography>
            )}
        </Stack>
    );
};

export default CreatePriorityLabels;
