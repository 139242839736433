import { atom } from "recoil";
import { chatSessionsModel } from "./model";

export const yourChatsAtom = atom<chatSessionsModel>({
    key: "yourChatsAtom",
    default: [
        {
            sessionId: 0,
            sessionName: "Hello Neti!",
            participants: ['Neti', 'User'],
            messages: [
                {
                    sender: 'Neti',
                    content: 'Hello, I am your buddy. Please ask me anything and I will be happy to assist.',
                    timestamp: '.',
                },
            ]
        },
    ]
});

export const activeChatSessionIndexAtom = atom<Number>({
    key: "activeChatSessionIndexAtom",
    default: 1
});

export const systemPromptAtom = atom<string> ({
    key: "systemPromptAtom",
    default: ".....loading......"
})