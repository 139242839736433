import { Today } from "@mui/icons-material";
import { BUD_BASE_LINK } from "./main";
import { now } from "moment";
import { MessageModel, yourChatsAtom } from "../recoil/chat";
import { useRecoilValue } from "recoil";

const POST_CHAT_MESSAGE: string = BUD_BASE_LINK + "bud/post?user_dummy=";
const GET_CHAT_MESSAGES: string = BUD_BASE_LINK + "bud/messages?user_dummy="
const GET_SYSTEM_PROMPT: string = BUD_BASE_LINK + "bud/prompt/get"
const POST_SYSTEM_PROMPT: string = BUD_BASE_LINK + "bud/prompt/update"

export function postChatMessage(token: string, currentUserId: string, sessionId: string,
    inMessage: MessageModel, chatHistory: MessageModel[]|undefined, updateChatSessions: any): any {
    console.log("posting chat message for user:", currentUserId);
    fetch(POST_CHAT_MESSAGE+currentUserId, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
            user_id: currentUserId,
            session_id: sessionId,
            messages: chatHistory? chatHistory.concat([inMessage]) : [inMessage]
        })
    })
        .then((response) => response.json())
        .then((data) => {
            console.log("response from backend:", data)
            console.log("chat_history:", chatHistory)
            // setReply(data)
            updateChatSessions([ 
                inMessage,            
            {
                sender: data.sender,
                content: data.content,
                timestamp: data.timestamp
            },
        ]
        )
    })
        .catch((err) => console.log("Response error: " + err));
}

export function getChatMessages(token: string, currentUserId: string, sessionId: string, setChatMessages: Function, updateChatSessions: Function): any {
    console.log("getting chat messages for user:", currentUserId);
    fetch(GET_CHAT_MESSAGES+currentUserId+"&sessionId="+sessionId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            console.log("response from backend:", data)
            setChatMessages([data])
            // if (data) {
            //     setChatMessages([data])
            // }
            // else {
            //     postChatMessage(token, 'Neti', sessionId, "Hello, I am your buddy. Please ask me anything and I will be happy to assist.",updateChatSessions)
            // }
            
    })
        .catch((err) => console.log("Response error: " + err));
}

export function getSystemPrompt(token: string, setSystemPrompt: Function): any {
    fetch(GET_SYSTEM_PROMPT, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            console.log("response from backend:", data)
            setSystemPrompt(data['system_prompt'])
            // if (data) {
            //     setChatMessages([data])
            // }
            // else {
            //     postChatMessage(token, 'Neti', sessionId, "Hello, I am your buddy. Please ask me anything and I will be happy to assist.",updateChatSessions)
            // }
            
    })
        .catch((err) => console.log("Response error: " + err));
}

export function postSystemPrompt(token: string, system_prompt: string): any {
    fetch(POST_SYSTEM_PROMPT, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
            system_prompt: system_prompt,
        })
    })
        .then((response) => response.json())
        .then((data) => {
            console.log("response from backend:", data)
    })
        .catch((err) => console.log("Response error: " + err));
}

