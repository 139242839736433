import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { CreatePriorityLabelModel } from "../recoil/topic";

const CORNER_RADIUS = 4;
const VERTICAL_SPACING = 4;

interface PriorityLabelSingleProps {
    label: CreatePriorityLabelModel;
    isSelected: boolean;
    onLabelSelected: (label: CreatePriorityLabelModel) => void;
    backgroundColor: string;
}

const PriorityLabelSingle: React.FC<PriorityLabelSingleProps> = ({
    label,
    isSelected,
    onLabelSelected,
    backgroundColor,
}) => {
    return (
        <Button
            variant="contained"
            startIcon={isSelected ? <DoneIcon /> : undefined}
            size="small"
            onClick={() => onLabelSelected(label)}
            disableElevation
            style={{
                background: backgroundColor,
                color: "black",
                fontWeight: "400",
                textTransform: "none",
                borderRadius: `${CORNER_RADIUS}px`,
                padding: `${VERTICAL_SPACING}px 10px`,
            }}
        >
            {label.topic_name}
        </Button>
    );
};

export default PriorityLabelSingle;
