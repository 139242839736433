import { Typography } from "@mui/material";

interface FormInputItemHeaderProps {
    label: string;
    fontWeight?: "bold" | "normal";
    marginBottom?: number;
}

const FormInputItemHeader: React.FC<FormInputItemHeaderProps> = ({
    label,
    fontWeight = "normal",
    marginBottom = 0,
}) => {
    return (
        <Typography variant="subtitle1" sx={{ fontWeight: fontWeight, marginBottom: marginBottom }}>
            {label}
        </Typography>
    );
};

export default FormInputItemHeader;
