import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import ChatIcon from "@mui/icons-material/Chat";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
// import Logo from "../../assets/neti_logo.png";
// import Logo from "../../assets/neti_logo_d4c0f0.jpg";
import Logo from "../../assets/neti_logo_d4c0f0_bgGray.jpg";
// import Logo from "../../assets/neti_logo_efbfdd.jpg";
// import Logo from "../../assets/neti_logo_transparent.png";
import { alpha, CssBaseline, InputBase, Menu, MenuItem, styled, Tooltip } from "@mui/material";
import { displayInMobile } from "../../constants/styles";
import useNavBar, { drawerWidth } from "../../hooks/UseNavBar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import useNotificationApi from "../../hooks/UseNotificationApi";
import { RouterIconLink } from "../RouterLink";
import { useNavigate } from "react-router-dom";
import usePriorityUtils from "../../utils/PriorityUtils";
import usePriorityApi from "../../hooks/UsePriorityApi";
import { useSetRecoilState } from "recoil";
import { searchPrioritiesAtom } from "../../recoil/priority";
import { getChatMessages } from "../../api/buddy";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));


interface MainNavBarProps {
    username: string;

    // turn off type checking
    signOut: (params?: any) => any;
    client: any;
}

function MainNavBar(props: MainNavBarProps) {
    const {
        sideNavBarMobileOpen,
        setSideNavBarMobileOpen,
        navBarMobileMoreAnchorEl,
        setNavBarMobileMoreAnchorEl,
        navBarAccountAnchorEl,
        setNavBarAccountAnchorEl,
    } = useNavBar();

    const {searchTerm, setSearchTerm} = usePriorityUtils();

    const { notifications } = useNotificationApi();

    const { fetchPrioritiesByKeyword } = usePriorityApi();

    const setSearchPriorities = useSetRecoilState(searchPrioritiesAtom);

    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setSideNavBarMobileOpen(!sideNavBarMobileOpen);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setNavBarAccountAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setNavBarMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setNavBarAccountAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        handleMenuClose();
        if (index === 3) {
            props.signOut();
        }
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setNavBarMobileMoreAnchorEl(event.currentTarget);
    };

    const onNotificationsMobileClick = () => {
        handleMobileMenuClose();
        navigate("/notifications");
    };

    const onChatMobileClick = () => {
        handleMobileMenuClose();
        navigate("/buddy")
    }

    const menuId = "primary-search-account-menu";
    const mobileMenuId = "primary-search-account-menu-mobile";

    const isMenuOpen = Boolean(navBarAccountAnchorEl);
    const isMobileMenuOpen = Boolean(navBarMobileMoreAnchorEl);
    const iconColor="#d4c0f0"
    // const iconColor="#aa68f9"
    const renderMenu = (
        <Menu
            anchorEl={navBarAccountAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={(event) => handleMenuClick(event, 1)}>Profile</MenuItem>
            <MenuItem onClick={(event) => handleMenuClick(event, 2)}>My account</MenuItem>
            <MenuItem onClick={(event) => handleMenuClick(event, 3)}>Sign Out</MenuItem>
        </Menu>
    );

    const renderMobileMenu = (
        <Menu
            anchorEl={navBarMobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={onChatMobileClick}>
                <IconButton size="large" color="inherit" >
                    <Badge badgeContent={0} color="error">
                        <ChatIcon 
                        sx={{color:iconColor}}
                        />
                    </Badge>
                </IconButton>
                <p>Neti Buddy</p>
            </MenuItem>
            <MenuItem onClick={onNotificationsMobileClick}>
                <IconButton size="large" color="inherit">
                    <Badge badgeContent={notifications.length} color="error">
                        <NotificationsIcon 
                            sx={{color:iconColor}}
                            />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton size="large" color="inherit">
                    <AccountCircle 
                        sx={{color:iconColor}}
                        />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    const handleKeydown = (e) => {
        if (e.key === 'Enter')
        {
            if (searchTerm.length > 1) {
            console.log("search term is:", searchTerm)
            setSearchPriorities([])
            fetchPrioritiesByKeyword(searchTerm)
            navigate('./search?term='+searchTerm.valueOf())
        }
        else {
            console.log("search term is:", searchTerm)
            // setSearchTerm("")
            setSearchPriorities([])
            navigate('./search')
        }
    }
    };

    const handleSearchInput = (e) => {
        if (e.key !== 'Enter') {
            setSearchTerm(e.target.value)
        }  
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#f2f0f0"}}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, ...displayInMobile}}
                    >
                        <MenuIcon 
                            sx={{color:iconColor}}
                            />
                    </IconButton>
                    <Box
                        width={drawerWidth - 24}
                        mr={0}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            verticalAlign: "middle",
                            marginLeft: 0
                        }}
                    >
                        <RouterIconLink to="/">
                        <Box
                            component="img"
                            sx={{
                                height: 56,
                                backgroundColor: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight:"24px"
                            }}
                            alt="Neti logo"
                            src={Logo}
                        /></RouterIconLink>
                    </Box>
                    
                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ flexGrow: 1, bgcolor:"white"}} />
                    <Search sx={{ display: { xs: "flex", md: "flex" }, 
                        color:"#000000", bgcolor:"white"
                        }}>
                        <SearchIconWrapper>
                            <SearchIcon 
                            sx={{color:iconColor}}
                            />
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Search…" onChange = {(e) => handleSearchInput(e)} onKeyDown={(e) => handleKeydown(e)}/>
                    </Search>

                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <RouterIconLink to="/buddy">
                        <Tooltip title="Ask the buddy">
                        <IconButton size="large" color="inherit" onClick={()=>{}} sx={{bgcolor:"white", marginRight:"10px"}}>
                            <Badge badgeContent={0} color="error">
                                <ChatIcon 
                                sx={{color:iconColor}}
                                />
                            </Badge>
                        </IconButton>
                        </Tooltip>
                        </RouterIconLink>
                        <RouterIconLink to="/notifications">
                            <Tooltip title="Notifications">
                            <IconButton size="large" color="inherit" sx={{bgcolor:"white", marginRight:"10px"}}>
                                <Badge badgeContent={notifications.length} color="error">
                                    <NotificationsIcon 
                                    sx={{color:iconColor}}
                                    />
                                </Badge>
                            </IconButton>
                            </Tooltip>
                        </RouterIconLink>
                        <IconButton size="large" edge="end" onClick={handleProfileMenuOpen} color="inherit" sx={{bgcolor:"white", marginRight:"10px"}}>
                            <AccountCircleIcon 
                                sx={{color:iconColor}}
                                />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton size="large" onClick={handleMobileMenuOpen} color="inherit">
                            <MoreIcon 
                            sx={{color:iconColor}}
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            {renderMobileMenu}
            {renderMenu}
            <CssBaseline />
        </Box>
    );
}

export default MainNavBar;
