import { useRecoilState } from "recoil";
import { Auth } from "aws-amplify";
import { getAllNotifications, getUnreadNotifications, putNotificationSeen } from "../api/notifications";
import { notificationsAtom, allNotificationsAtom } from "../recoil/notification";



const useNotificationApi = () => {
    const [notifications, setNotifications] = useRecoilState(notificationsAtom);
    const [allNotifications, setAllNotifications] = useRecoilState(allNotificationsAtom);

    const fetchNotifications = async() => {
        console.log("Fetching Notifications from API");
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getUnreadNotifications(token, currentUserId, setNotifications);
        getAllNotifications(token, currentUserId, setAllNotifications);
    };


    const flagNotificationSeen = async(id) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const token = currentUser.signInUserSession.idToken.jwtToken;
        putNotificationSeen(token, id);
    }

    return {
        notifications,
        setNotifications,
        allNotifications,
        setAllNotifications,
        fetchNotifications,
        flagNotificationSeen,
    };
};

export default useNotificationApi;
