import {Box, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Route } from 'react-router';
import { RouterIconLink } from './RouterLink';


const CreatePriorityButton = () => {


    return <div>
        <Box marginBottom="20px" marginTop="-10px">
        <RouterIconLink to="/create-priority">
        <Button
                                    // onClick={onReset}
                                    variant="outlined"
                                    disableElevation
                                    sx={{ padding: "4px 18px", fontSize: "0.8rem" }}
                                    href="/create-priority"
                                >
                                    Create New Priority
                                </Button>
                                </RouterIconLink>
        </Box>
        </div>

};

export default CreatePriorityButton;