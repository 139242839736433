import { useState, useEffect } from "react";
import { Mention, MentionsInput } from "react-mentions";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import { Divider } from '@mui/material';
import usePriorityApi from "../hooks/UsePriorityApi";

interface AddCommentProps {
    priorityId: number;
}

const AddCommentComponent = (props: AddCommentProps) => {

    const { addComment } = usePriorityApi();
    const [value, setValue] = useState("");
    const users = [
        {
            id: "isaac",
            display: "Isaac Newton",
        },
        {
            id: "sam",
            display: "Sam Victor",
        },
        {
            id: "emma",
            display: "emmanuel@nobody.com",
        },
    ];

    const onSubmit = async () => {
        console.log("Post comment button was clicked!", value, JSON.stringify(props));
        addComment(value, props.priorityId);
        setValue("");
    };

    useEffect(() => {
        console.log("useEffect from AddComment.tsx");
    }, []);

    return (
        <div className="App">
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Comments
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <MentionsInput
                        style={mentionsInputStyle}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        >

                        <Mention
                            style={mentionStyle}
                            data={users}
                        />
                    </MentionsInput>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        disableElevation
                        sx={{ padding: "6px 18px", fontSize: "0.8rem" }}
                    >
                        Post
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

const mentionStyle = {
    backgroundColor: "#cee4e5",
};

const mentionsInputStyle = {
    control: {
        backgroundColor: '#fff',
        fontSize: 16,
        fontFamily: 'monospace',
        minHeight: 63,
    },
    input: {
        height: 80,
        overflow: "auto",
    },
    highlighter: {
        height: 80,
        overflow: "hidden",
        boxSizing: "border-box",
    },
    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 16,
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
}

export default AddCommentComponent