import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SearchPriorities from "../components/SearchPriorities";
import SearchBar from "../components/navbar/SearchBar";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import ExplorePriorityCard from "../components/explore/ExplorePriorityCard";
import WorkIcon from "@mui/icons-material/Work";
import PublicIcon from '@mui/icons-material/Public';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';

interface SearchPageProps {
    client: any;
}

function SearchPage(props: SearchPageProps) {

    const [searchParams] = useSearchParams();

    const searchTerm = searchParams.get("term")

    const displayText = searchTerm ? <div>Top results for "{searchTerm}"</div> : <div>Enter a search term to explore</div>
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const mobileSpan = "span 12";

    return (
        <Box m="20px" width="100%">
            <Toolbar />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" mb={4} sx={{ fontWeight: "bold" }}>
                    EXPLORE
                </Typography>
            </Box>
            <Box display="flex" justifyContent="left" alignItems="left" mb={4}>
                <SearchBar />
                <Tooltip title="Priorities by champs">
                    <IconButton>
                        <EmojiEventsIcon sx={{ color: "green" }} />
                        <Typography>Champ</Typography>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Trending priorities">
                    <IconButton>
                        <WhatshotIcon sx={{ color: "red" }} />
                        <Typography>Hot</Typography>
                    </IconButton>
                </Tooltip>
            </Box>
            <Accordion sx={{ margin: "5px", maxWidth:"1000px" }}>
                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                    <Box gridColumn={"span 12"} gridAutoRows="20px" display="flex">
                        <IconButton>
                            <WorkIcon />
                            <Typography variant="h6" marginLeft="5px" sx={{fontWeight:"600"}}>Work</Typography>
                        </IconButton>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Accordion sx={{ margin: "5px" }}>
                <AccordionSummary expandIcon={<GridExpandMoreIcon />}> */}
                    <Box gridColumn={"span 12"} gridAutoRows="20px">
                        <Typography variant="h6">Skill Building</Typography>
                    </Box>
                    {/* </AccordionSummary> */}
                    {/* <AccordionDetails> */}
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">


                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>


                    </Box>
                    {/* </AccordionDetails> */}
                    {/* </Accordion> */}
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Networking</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">


                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">SOS</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">



                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Open Challenge</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">



                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ margin: "5px", maxWidth:"1000px" }}>
                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">
                        <IconButton>
                            <PublicIcon />
                            <Typography variant="h6" marginLeft="5px" sx={{fontWeight:"600"}}>For Good</Typography>
                        </IconButton>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">
                        <Typography variant="h6">Planet</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">



                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Community</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">


                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Health</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Mind</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">



                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ margin: "5px", maxWidth:"1000px" }}>
                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">
                        <IconButton>
                            <DownhillSkiingIcon />
                            <Typography variant="h6" marginLeft="5px" sx={{fontWeight:"600"}}>For Fun</Typography>
                        </IconButton>

                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">
                        <Typography variant="h6">Performing Arts</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">


                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Sports</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">


                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Geek club</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">


                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                    </Box>
                    <Box gridColumn={"span 12"} gridAutoRows="20px">

                        <Typography variant="h6">Books</Typography>
                    </Box>
                    <Box display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px">


                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>

                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                        <Box gridColumn={isSmScreen ? mobileSpan : "span 3"} display="flex" alignItems="center" justifyContent="center" gridAutoFlow="column">
                            <ExplorePriorityCard title={"Learn to use generative AI in consumer applications"} createdDate={"24 Sept"} deadline={"20 days to go"} collaborators={45} />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default SearchPage;
