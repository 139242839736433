import { selector, selectorFamily, RecoilState } from "recoil";
import { ListPriorityModel } from "./model";
import { getBucketListPrioritiesApi, getYourPrioritiesApi } from "../../api/Priority";
import { Auth } from "aws-amplify";

export const prioritySelectedDetailItemAtom = selectorFamily<
    ListPriorityModel | null,
    { listAtom: RecoilState<ListPriorityModel[]>; priorityId: number }
>({
    key: "priorityDetailItemAtom",
    get:
        ({ listAtom: listState, priorityId }) =>
        ({ get }) => {
            const list = get(listState);
            return list.find((e) => e.id === priorityId) ?? null;
        },
});

// For the api calls. Parked it for now, but we can move to this format in future to directly initialize atoms using this

export const yourPrioritiesSelector = selector ({
    key: "yourPrioritiesSelector",
    get: async ({get}) => {
        const data = await getYourPrioritiesApi()
        return data
    }
})

// export const bucketListPrioritiesSelector = selector ({
//     key: "bucketListPrioritiesSelector",
//     get: async ({get}) => {
//         const currentUser = await Auth.currentAuthenticatedUser();
//         const currentUserId = currentUser.attributes["email"];
//         const token = currentUser.signInUserSession.idToken.jwtToken;
//         const data = await getBucketListPrioritiesApi(token, currentUserId)
//         return data
//     }
// })