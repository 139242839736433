import { Box, Grid, Paper, Card, Stack, Avatar, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import GroupsIcon from '@mui/icons-material/Groups';

interface ExplorePriorityCardProps {
    // index: number;
    title: string;
    createdDate?: string;
    deadline?:string;
    collaborators?:number;
    // description: string;
};

const ExplorePriorityCard = (props: ExplorePriorityCardProps) => {
    return <Card
            key={props.title}
            // onClick={() => onPrioritySelect(priority)}
            sx={{ marginBottom: "12px", display: "flex", width:"100%" , height:"100%"}}
        >
            <Stack
                direction={"column"}
                flexGrow={1}
                sx={{
                    padding: "10px 10px",
                }}
            >
                <Stack
                    justifyContent="space-around"
                    direction={"row"}
                    alignItems={"center"}
                >
                    <Typography
                        variant="body1"
                        align="left"
                        sx={{
                            width: "min-content",
                            flexGrow: 1,
                            margin: "0 2",
                        }}
                    >
                        {props.title}
                    </Typography>
                </Stack>
                <Stack
                    justifyContent="space-around"
                    direction={"row"}
                    alignItems={"center"}
                >
                <EventIcon/>
                <TimelapseIcon/>
                <GroupsIcon/>
                
                </Stack>
                <Stack
                    justifyContent="space-around"
                    direction={"row"}
                    alignItems={"center"}
                >
                    <Typography>{props.createdDate}</Typography>
                <Typography variant="subtitle2" sx={{color:"red"}} alignContent={"right"}>{props.deadline}</Typography>
                <Typography variant="h6" sx={{color:"green"}} alignContent={"right"}>{props.collaborators}</Typography>
                </Stack>
    
                
            </Stack>
        </Card>                         
};

export default ExplorePriorityCard;