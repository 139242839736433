import { Box } from "@mui/material";
import { SuggestionProps } from "@tiptap/suggestion";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import useCreatePriorityForm from "../../hooks/UserCreatePriorityForm";
import useUsersApi from "../../hooks/UseUsersApi";
import { UserTagModel } from "../../recoil/user";
import { UserTagMapModel } from "../../recoil/priority";
import MentionItem from "./MentionItem";

interface MentionListProps extends SuggestionProps<UserTagModel> {
    onUserTagsChanged: (usertag: UserTagMapModel) => void;
}

interface MentionListActions {}

export const MentionList = forwardRef<MentionListActions, MentionListProps>((props, ref) => {
    const { userTags } = useUsersApi();

    const { clientRect, command, query } = props;

    const getClient = (): (() => DOMRect) => {
        const emptyDomRect = {
            height: 0,
            left: 0,
            top: 0,
            width: 0,
        } as DOMRect;

        const client = () => {
            if (!clientRect) return emptyDomRect;
            const rect = clientRect();
            return rect ?? emptyDomRect;
        };
        return client;
    };

    const referenceEl = useMemo(() => {
        const client = getClient();
        if (!client) return null;
        return { getBoundingClientRect: client };
    }, [clientRect]);

    const [filteredUsers, setFilteredUsers] = useState<UserTagModel[]>([]);

    useEffect(() => {
        console.log("useEffect from MentionList.tsx");
        const filtered = userTags.filter((user) => user.label.toLowerCase().includes(query.toLowerCase()));
        setFilteredUsers(filtered);
    }, [query]);

    const { usersTagged, setUsersTagged, onUserTagsChanged } = useCreatePriorityForm();

    const handleCommand = (index) => {
        const selectedUser = filteredUsers[index];
        console.log("filtered users:", filteredUsers);
        console.log("index: ", index);
        console.log("selected user:", filteredUsers[0]);
        console.log("selecteduservar:", selectedUser);
        if (!selectedUser) {
            console.log("No selected user");
            return;
        }
        console.log("there was a selected user");
        command(selectedUser);
        console.log("previous usertags", usersTagged);
        onUserTagsChanged(usersTagged);
    };

    const [hoverIndex, setHoverIndex] = useState(0);
    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            const { key } = event;

            if (key === "ArrowUp") {
                setHoverIndex((prev) => {
                    const beforeIndex = prev - 1;
                    return beforeIndex >= 0 ? beforeIndex : 0;
                });
                return true;
            }

            if (key === "ArrowDown") {
                setHoverIndex((prev) => {
                    const afterIndex = prev + 1;
                    const userCount = filteredUsers.length - 1 ?? 0;
                    return afterIndex < userCount ? afterIndex : userCount;
                });
                return true;
            }

            if (key === "Enter") {
                handleCommand(hoverIndex);
                return true;
            }

            return false;
        },
    }));

    const [el, setEl] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(referenceEl, el, {
        placement: "bottom-start",
    });

    return createPortal(
        <Box
            ref={setEl}
            style={styles.popper}
            {...attributes.popper}
            sx={{
                maxHeight: "40vh",
                borderRadius: "0.25rem",
                backgroundColor: "#ffffff",
                boxShadow: "0 2px 3px 1px rgba(0, 0, 0, 0.15)",
                overflow: "auto",
            }}
        >
            {filteredUsers.map((user, index) => (
                <MentionItem
                    key={user.id}
                    isActive={index === hoverIndex}
                    onMouseEnter={() => setHoverIndex(index)}
                    onClick={() => handleCommand(index)}
                >
                    {user.label}
                </MentionItem>
            ))}
        </Box>,
        document.body
    );
});
