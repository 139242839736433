import { Dialog, DialogContent, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { PriorityRequestModel } from "../recoil/priority";

interface PriorityRequestDetailProps {
    priorityRequest: PriorityRequestModel;
    onCloseDialog: () => void;
    PriorityDate;
    PriorityActions;
    onAcceptClick,
    onRejectClick,
    onMoreInfoClick,
    onMoveToBucketListClick
   
}

const PriorityRequestDetail: React.FC<PriorityRequestDetailProps> = ({
    priorityRequest,
    onCloseDialog,
    PriorityDate,
    PriorityActions,
    onAcceptClick,
    onRejectClick,
    onMoreInfoClick,
    onMoveToBucketListClick
}) => {
    // const priority = useRecoilValue(prioritySelectedDetailItemAtom({ listAtom, priorityId }));

    useEffect(() => {
        console.log("useEffect from PriorityDetailPage.tsx");
        if (priorityRequest === null) onCloseDialog();
    }, [priorityRequest]);

    const handleClose = (reason: string) => {
        if (reason == "backdropClick") onCloseDialog();
    };

    const Header: React.FC<{ text: string }> = ({ text }) => {
        return <Typography variant="h6" sx={{ fontweight: "bold"}} mb={4}>{text}</Typography>;
    };

    if (!priorityRequest) return <div></div>;

    return (
        <Dialog open fullWidth onClose={(_, reason: string) => handleClose(reason)}>
            <DialogContent dividers>
                <Stack direction={"column"}>
                    {/* <Header text={"Priority Request from " + priorityRequest.originator_user}/> */}
                    <Stack direction={"row"}>
                    <Typography variant="h5" mb={4} sx={{ fontWeight: "bold" }}>
                        Priority Request from {priorityRequest.originator_user}
                        </Typography>
                    <IconButton onClick={onCloseDialog} >
                    <CloseIcon />
                    </IconButton>
                    </Stack>
                        <Typography
                                variant="h6"
                                align="left"
                                // sx={{
                                //     width: "min-content",
                                //     flexGrow: 1,
                                //     margin: "0 2",
                                // }}
                            >
                               <b>Priority title:</b> {priorityRequest.priority.title}
                            </Typography>
                    <Stack
                        direction={"column"}
                        flexGrow={1}
                        sx={{
                            padding: "10px 20px",
                        }}
                    >
                        <Stack justifyContent="space-around" direction={"row"} alignItems={"center"}>
 
                                <Stack direction={"column"} ml={2} alignItems={"center"}>
                                    <PriorityDate priority={priorityRequest.priority} />
                                    <PriorityActions
                                        priorityRequest={priorityRequest}
                                        onAcceptClick={onAcceptClick}
                                        onRejectClick={onRejectClick}
                                        onMoreInfoClick={onMoreInfoClick}
                                        onMoveToBucketListClick={onMoveToBucketListClick}
                                    />
                                </Stack>
                        
                        </Stack>
                    </Stack>
                </Stack>

            </DialogContent>
        </Dialog>
    );
};

export default PriorityRequestDetail;
