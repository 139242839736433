import { CreatePriorityModel } from "../recoil/priority";
import { ListPriorityModel } from "../recoil/priority";
import { API_BASE_LINK } from "./main";
import { Auth } from "aws-amplify";

const GET_PRIORITIES_URL: string = API_BASE_LINK + "api/priorities/type=main?user_dummy=";
const GET_BUCKET_PRIORITIES_URL: string = API_BASE_LINK + "api/priorities/type=bucket?user_dummy=";
const TOGGLE_PRIORITIES_URL = API_BASE_LINK + "api/priority/update/play_pause";
const CLOSE_PRIORITY_URL = API_BASE_LINK + "api/priority/update/close?user_dummy=";
const POST_PRIORITY_URL: string = API_BASE_LINK + "api/priority/create";
const POST_PRIORITY_COMMENT_URL: string = API_BASE_LINK + "api/priority/comment";
const GET_PRIORITY_COMMENT_URL: string = API_BASE_LINK + "api/priority/comment?priority_id=";
const CHANGE_TYPE_API_URL: string = API_BASE_LINK + "api/priority/update/type?user_dummy=";
const CHANGE_ORDER_API_URL: string = API_BASE_LINK + "api/priority/update/order?user_dummy=";
const GET_HISTORICAL_PRIORITIES_URL: string = API_BASE_LINK + "api/priorities/type=history?user_dummy=";
const NETI5_SCHEDULE_URL: string = API_BASE_LINK + "api/schedule?user_dummy=";
const GET_SEARCH_PRIORITIES_URL: string = API_BASE_LINK + "api/priorities/keyword=";

export function getYourPriorities(
    token: string,
    currentUserId: string,
    setYourPriorities,
    onSuccess: () => void = () => { }
): void {

    fetch(GET_PRIORITIES_URL + currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => {
            onSuccess();
            return response.json();
        })
        .then((data) => {
            setYourPriorities(data);
        })
        .catch((err) => console.log("Response error: " + err));
}

export function getPriorityComments(
    token: string,
    currentUserId: string,
    priority_id: number,
    setPriorityComments,
    onSuccess: () => void = () => { }
): void {

    fetch(GET_PRIORITY_COMMENT_URL + priority_id, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => {
            onSuccess();
            return response.json();
        })
        .then((data) => {
            setPriorityComments((prevData) => ({ ...prevData, [priority_id]: data }));
        })
        .catch((err) => console.log("Response error: " + err));
}

export function getBucketListPriorities(token: string, currentUserId: string, setBucketListPriorities): void {
    fetch(GET_BUCKET_PRIORITIES_URL + currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setBucketListPriorities(data);
        })
        .catch((err) => console.log("Response error: " + err));
}

export function putStateChange(priority, token: string, currentUserId: string, 
    setYourPriorities, onSuccess, target_users: Array<string | undefined>): void {
    fetch(TOGGLE_PRIORITIES_URL, {
        method: "PUT",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ id: priority.id }),
    })
        .then((response) => response.json())
        .then((data) => {
            onSuccess(target_users);
        })
        .catch((err) => console.log("Response error: " + err));

    setYourPriorities((list) =>
        list.map((p) => (p.id === priority.id ? { ...p, state: priority.state === "Active" ? "Paused" : "Active" } : p))
    );
}

export function putPriorityClosure(formData, token: string, currentUserId: string, 
    setYourPriorities, setHistoricalPriorities, onSuccess, priority:ListPriorityModel): void {
    fetch(CLOSE_PRIORITY_URL + currentUserId, {
        method: "PUT",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
            id: formData.priorityId,
            close_reason: formData.reasonToClose,
            close_comment: formData.additionalComments,
        }),
    })
        .then((response) => response.json())
        .then((data) => {
            getYourPriorities(token, currentUserId, setYourPriorities);
            getHistoricalPriorities(token, currentUserId, setHistoricalPriorities);
            onSuccess(priority);
        })
        .catch((err) => console.log("Response error: " + err));
}

export function postPriorityComment(token: string, currentUserId: string, priority_id: number,
    commentText: string, setPriorityComments): void {
    const priorityComment = {
        priority: priority_id,
        comment_text: commentText,
        creator: currentUserId,
        tenant: 1
    }
    fetch(POST_PRIORITY_COMMENT_URL, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(priorityComment)
    })
        .then((response) => response.json())
        .then((data) => {
            getPriorityComments(token, currentUserId, priority_id, setPriorityComments);
        })
        .catch((err) => console.log("Response error: " + err));
}

export function postYourPriority(
    token: string,
    currentUserId: string,
    priority: CreatePriorityModel,
    setYourPriorities,
    setBucketListPriorities,
    onSuccess
): void {
    fetch(POST_PRIORITY_URL, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(priority),
    })
        .then((response) => response.json())
        .then((data) => {
            getYourPriorities(token, currentUserId, setYourPriorities);
            getBucketListPriorities(token, currentUserId, setBucketListPriorities);
            onSuccess(data);
        })
        .catch((err) => console.log("Response error: " + err));
}

export function changePriorityType(token: string, currentUserId: string, priority, setYourPriorities, setBucketListPriorities, onSuccess, target_users: Array<string | undefined>): void {
    fetch(CHANGE_TYPE_API_URL + currentUserId, {
        method: "PUT",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ id: priority.id })
    })
        .then((response) => response.json())
        .then((data) => {
            getBucketListPriorities(token, currentUserId, setBucketListPriorities);
            getYourPriorities(token, currentUserId, setYourPriorities);
            onSuccess(target_users);
        })
        .catch((err) => console.log("Response error: " + err));
};

export function getHistoricalPriorities(token: string, currentUserId: string, setHistoricalPriorities): void {
    fetch(GET_HISTORICAL_PRIORITIES_URL + currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setHistoricalPriorities(data);
        })
        .catch((err) => console.log("Response error: " + err));
};

export function getNeti5edSchedule(token: string, currentUserId: string, numWeeks: number, setNeti5edSchedule): void {
    fetch(NETI5_SCHEDULE_URL + currentUserId + '&num_weeks=' + numWeeks, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setNeti5edSchedule(data);
        })
        .catch((err) => console.log("Response error: " + err));
};

export function changePriorityOrder(token: string, currentUserId: string, 
    priority, new_priority_order, onSuccess: (user: string) => void): void {
    fetch(CHANGE_ORDER_API_URL + currentUserId, {
        method: "PUT",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ id: priority.id, new_priority_order: new_priority_order }),
    })
        .then((response) => response.json())
        .then((data) => {
            onSuccess(data.user);
        })
        .catch((err) => console.log("Response error: " + err));
};

export function getPrioritiesByKeyword(token: string, searchTerm: string, setSearchPriorities, setIsSearchLoading): void {
    fetch(GET_SEARCH_PRIORITIES_URL + searchTerm, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setSearchPriorities(data);
            setIsSearchLoading(false);
        })
        .catch((err) => console.log("Response error: " + err));
};

// Parked for future update, we can use this in selector of recoil to directly load default value from api call in atom

export async function getYourPrioritiesApi() {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentUserId = currentUser.attributes["email"];
    const token = currentUser.signInUserSession.idToken.jwtToken;
    const response = await fetch(GET_PRIORITIES_URL + currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
    const json = await response.json();
    return json
};

export async function getBucketListPrioritiesApi() {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentUserId = currentUser.attributes["email"];
    const token = currentUser.signInUserSession.idToken.jwtToken;
    const response = await fetch(GET_BUCKET_PRIORITIES_URL + currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
    const json = await response.json();
    return json
};

export async function getHistoricalPrioritiesApi() {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentUserId = currentUser.attributes["email"];
    const token = currentUser.signInUserSession.idToken.jwtToken;
    const response = await fetch(GET_HISTORICAL_PRIORITIES_URL + currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
    const json = await response.json();
    return json
};


