import React, { Suspense, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import YourPriorities from "../components/YourPriorities";
import BucketList from "../components/BucketList";
import PriorityRequests from "../components/PriorityRequests";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { yourPrioritiesListAtom } from "../recoil/priority";
import HistoricalPriorities from "../components/HistoricalPriorities";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: "#635ee7",
    },
});

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)({
    textTransform: "none",
    fontSize: "16px",
});

const tabsList = ["Your Priorities", "Bucket List", "Historical Priorities", "Priority Requests"];

function Loading() {
    return <h2>🌀 Loading...</h2>;
  }

interface CustomizedTabsProps {
client: any;
}

function CustomizedTabs(props: CustomizedTabsProps) {
    const getTabIndex = (searchParams): number => {
        return Number(searchParams.get("tabIndex")) || 0;
    };

    const getSelectedPriorityId = (searchParams): number => {
        return Number(searchParams.get("selectedId"));
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = React.useState(getTabIndex(searchParams));
    const [selectedId, setSelectedId] = React.useState<number>(getSelectedPriorityId(searchParams));

    const yourPriorities = useRecoilValue(yourPrioritiesListAtom);

    useEffect(() => {
        console.log("useEffect from PrioritiesPage.tsx");
        console.log("searchParams:", searchParams);
        setTabIndex(getTabIndex(searchParams));
        setSelectedId(getSelectedPriorityId(searchParams));
    }, [searchParams]);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setSearchParams({
            tabIndex: newValue.toString(),
        });
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                <StyledTabs value={tabIndex} onChange={handleChange}>
                    {tabsList.map((tab, index) => (
                        <StyledTab key={index} label={tab} />
                    ))}
                </StyledTabs>
                {tabIndex === 0 && 
                <Suspense fallback={<div>Loading components...</div>}>
                    <YourPriorities selectedPriorityId={selectedId} client={props.client} />
                    </Suspense>
                    }
                {tabIndex === 1 && 
                <Suspense fallback={<div>Loading components...</div>}>
                    <BucketList selectedPriorityId={selectedId} client={props.client}/>
                    </Suspense>
                    }
                {tabIndex === 2 && 
                <Suspense fallback={<div>Loading components...</div>}>
                    <HistoricalPriorities selectedPriorityId={selectedId} />
                    </Suspense>
                    }
                {tabIndex === 3 && 
                <Suspense fallback={<div>Loading components...</div>}>
                    <PriorityRequests selectedPriorityId={selectedId} client={props.client}/>
                    </Suspense>
                    }
            </Box>
        </Box>
    );
}

interface ListPrioritiesPageProps {
    client: any;
    }

function ListPrioritiesPage(props: ListPrioritiesPageProps) {
    return (
        <Box width={"100%"} px={4} py={4}>
            <Toolbar />
            <CustomizedTabs client={props.client}/>
        </Box>
    );
}

export default ListPrioritiesPage;
