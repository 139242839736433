import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DropResult,
    ResponderProvided,
} from "react-beautiful-dnd";

interface DragAndDropProps<T extends { id: number }> {
    droppableId: string;
    items: T[];
    renderItem: (item: T, index: number) => React.ReactNode;
    onDragEnd: (from: number, to: number, onSuccess) => void;
    onDragAndDropSuccess;
}

const DragAndDrop = <T extends { id: number }>(props: DragAndDropProps<T>) => {
    const _onDragEnd = (result: DropResult, _: ResponderProvided) => {
        const { source, destination } = result;
        if (!destination) return;
        if (source.index === destination.index) return;
        props.onDragEnd(source.index, destination.index, props.onDragAndDropSuccess);
    };
    return (
        <DragDropContext onDragEnd={_onDragEnd}>
            <Droppable droppableId={props.droppableId}>
                {(provided: DroppableProvided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            props && props.items && Array.isArray(props.items) ?
                                props.items.map((item: T, index: number) => {
                                    return (
                                        <Draggable key={index} draggableId={`index_${index}`} index={index}>
                                            {(provided: DraggableProvided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {props.renderItem(item, index)}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })
                            : <div/>
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDrop;
