import React, { useEffect, useState, Suspense } from "react";
import usePriorityApi from "../hooks/UsePriorityApi";
import {
    EventApi,
    DateSelectArg,
    EventClickArg,
    EventContentArg,
    formatDate,
    EventAddArg,
    EventChangeArg,
    EventRemoveArg,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createEventId } from './event-utils';
import Button from "@mui/material/Button";
import { Box, Grid, TextField } from "@mui/material";
import FormInputItemHeader from "./FormInputItemHeader";
import moment from 'moment';

interface Neti5ScheduleProps {
    weeks?: number
    weekendsVisible?: boolean,
    currentEvents?: EventApi[]
}

const Neti5Schedule: React.FC<Neti5ScheduleProps> = (props) => {
    const {
        invokeNeti5Schedule,
        neti5edSchedule
    } = usePriorityApi();

    const [weekendsVisible, setWeekendsVisible] = useState(props.weekendsVisible);
    const [currentEvents, setCurrentEvents] = useState(props.currentEvents);
    const [numWeeks, setNumWeeks] = useState(1);

    useEffect(() => {
        console.log("useEffect from Neti5Schedule");
    }, [props]);

    const handleDateSelect = (selectInfo: DateSelectArg) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    const handleEventClick = (clickInfo: EventClickArg) => {
        if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove()
        }
    }

    const handleEventAdd = (addInfo: EventAddArg) => {
        console.log("Added info: " + addInfo.event.id + " " + addInfo.event.title + " "
            + addInfo.event.start + " " + addInfo.event.end + " " + addInfo.event.allDay);
    }

    const handleEventChange = (changeInfo: EventChangeArg) => {
        console.log("Changed info: " + changeInfo.event.id + " " + changeInfo.event.title + " "
            + changeInfo.event.start + " " + changeInfo.event.end + " " + changeInfo.event.allDay);
    }

    const handleEventRemove = (removeInfo: EventRemoveArg) => {
        console.log("Removed info: " + removeInfo.event.id + " " + removeInfo.event.title + " "
            + removeInfo.event.start + " " + removeInfo.event.end + " " + removeInfo.event.allDay);
    }

    const handleEvents = (events: EventApi[]) => {
        setCurrentEvents(events);
    }

    const onSubmit = async () => {
        console.log("Neti5 Schedule button was clicked!");
        invokeNeti5Schedule(numWeeks);
    };

    const onDownloadClick = async () => {
        console.log("Download Neti5 Schedule button was clicked!");
        downloadNeti5Schedule(neti5edSchedule);
    };

    const downloadNeti5Schedule = (neti5edSchedule: {
        id: string;
        title: string; tenant: number; start: string; end: string; is_all_day: boolean;
    }[]
    ) => {
        let start = 'BEGIN:VCALENDAR' + '\n' +
            'VERSION:2.0' + '\n' +
            'PRODID:-//bobbin v0.1//NONSGML iCal Writer//EN' + '\n' +
            'CALSCALE:GREGORIAN' + '\n' +
            'METHOD:PUBLISH';
        let end = 'END:VCALENDAR'

        let fullIcs = start;
        neti5edSchedule.map((x) => {
            let createdTime = moment().format('YYYYMMDD HHmmss').replaceAll(' ', 'T');
            let currEvent = 'BEGIN:VEVENT' + '\n' +
                `DTSTART:${x.start.replaceAll('-', '').replaceAll(':', '')}` + "\n" +
                `DTEND:${x.end.replaceAll('-', '').replaceAll(':', '')}` + "\n" +
                `DTSTAMP:${createdTime}` + '\n' +
                `UID:${x.id + "@neti.com"}` + '\n' +
                `CREATED:${createdTime}` + '\n' +
                `DESCRIPTION:${x.title}` + '\n' +
                `LAST-MODIFIED:${createdTime}` + '\n' +
                'SEQUENCE:0' + '\n' +
                'STATUS:CONFIRMED' + '\n' +
                `SUMMARY:${x.title}` + '\n' +
                'TRANSP:OPAQUE' + '\n' +
                'END:VEVENT';
            fullIcs = fullIcs + '\n' + currEvent
            return currEvent
        });
        fullIcs = fullIcs + '\n' + end;
        const element = document.createElement("a");
        const file = new Blob([fullIcs], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "neti5ed_calendar.ics";
        document.body.appendChild(element);
        element.click();
    }

    const handleNumWeeksChange = (event) => {
        setNumWeeks(event.target.value);
    };

    return (
        <Suspense fallback={<div>Loading components...</div>}>
            <Box width={"100%"} sx={{ paddingTop: 4 }}>
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item md={4} xs={6} sx={{ paddingTop: 2 }}>
                        <FormInputItemHeader label={"Num Weeks to plan: "} fontWeight="bold" />
                    </Grid>
                    <Grid item md={4} xs={6} sx={{ paddingTop: 2 }}>
                        <TextField id="num-weeks-to-plan" variant="outlined" value={numWeeks}
                            onChange={handleNumWeeksChange} />
                    </Grid>
                    <Grid item md={12} xs={12}/>
                    <Grid item md={3} xs={4} sx={{ paddingTop: 2 }}>

                        <div>
                            <Button
                                onClick={onSubmit}
                                variant="contained"
                                fullWidth
                                disableElevation
                                sx={{ padding: "4.5px 18px", fontSize: "0.8rem" }}
                            >
                                Neti5 Schedule
                            </Button>
                        </div>

                    </Grid>
                    <Grid item md={2} xs={4} sx={{ paddingTop: 2 }}></Grid>
                    <Grid item md={3} xs={4} sx={{ paddingTop: 2 }}>
                        <div>
                            <Button
                                onClick={onDownloadClick}
                                variant="contained"
                                fullWidth
                                disableElevation
                                sx={{ padding: "4.5px 18px", fontSize: "0.8rem" }}
                            >
                                Download Schedule
                            </Button>
                        </div>
                    </Grid>
                    <Grid item md={12} xs={12}/>
                    <Grid item md={8} xs={12} sx={{ paddingTop: 2 }}>
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            initialView='dayGridMonth'
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={weekendsVisible}
                            initialEvents={neti5edSchedule} // alternatively, use the `events` setting to fetch from a feed
                            select={handleDateSelect}
                            eventContent={renderEventContent} // custom render function
                            eventClick={handleEventClick}
                            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                            eventAdd={handleEventAdd}
                            eventChange={handleEventChange}
                            eventRemove={handleEventRemove}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Suspense>
    );
}

function renderEventContent(eventContent: EventContentArg) {
    return (
        <>
            <b>{eventContent.timeText}</b>
            <i>{eventContent.event.title}</i>
        </>
    )
}

function renderSidebarEvent(event: EventApi) {
    return (
        <li key={event.id}>
            <b>{formatDate(event.start!, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
            {' '}
            <i>{event.title}</i>
        </li>
    )
}

export default Neti5Schedule;
