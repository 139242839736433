import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ListIcon from "@mui/icons-material/List";
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WorkIcon from "@mui/icons-material/Work";
import InsightsIcon from '@mui/icons-material/Insights';
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import useNavBar, { drawerWidth } from "../../hooks/UseNavBar";
import { RouterLink } from "../RouterLink";
import { useState } from "react";
import { none } from "fp-ts/lib/Option";

interface SideNavBarProps {
    username: string;

    // turn off type checking
    signOut: (params?: any) => any;
}

function SideNavBar(props: SideNavBarProps) {
    const {
        sideNavBarSelectedItemIndex,
        setSideNavBarSelectedItemIndex,
        sideNavBarMobileOpen,
        setSideNavBarMobileOpen,
        sideNavBarOpen,
        setSideNavBarOpen,
    } = useNavBar();

    const [sideNavVibeOpen, setsideNavVibeOpen] = useState(true)
    const [sideNavFlowOpen, setsideNavFlowOpen] = useState(true)

    const handleDrawerToggle = () => {
        setSideNavBarMobileOpen(!sideNavBarMobileOpen);
    };

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        setSideNavBarSelectedItemIndex(index);
        if (index === 0) {
            // If clicked on "Deep Work", we toggle between collapsing/expanding the sub-menu
            setSideNavBarOpen(!sideNavBarOpen);
            setsideNavVibeOpen(false);
            setsideNavFlowOpen(false);

            // handleDrawerToggle();
        } else if (index === 6) {
            setsideNavVibeOpen(!sideNavVibeOpen)
            setSideNavBarOpen(false);
            setsideNavFlowOpen(false);
            // if (sideNavBarOpen) setSideNavBarSelectedItemIndex(4);
            // handleDrawerToggle();
        } else if (index === 7) {
            setsideNavFlowOpen(!sideNavFlowOpen);
            setSideNavBarOpen(false);
            setsideNavVibeOpen(false);
            // if (sideNavBarOpen) setSideNavBarSelectedItemIndex(12);
            // handleDrawerToggle();
        } else if (index === 11) {
            props.signOut();
        } else {
            handleDrawerToggle();
        }
    };

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List
                sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <RouterLink to="/">
                <ListItemButton
                    selected={sideNavBarSelectedItemIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                >
                    <ListItemIcon>
                        <WorkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Zone" />
                    {sideNavBarOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                </RouterLink>
                <Collapse in={sideNavBarOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <RouterLink to="/">
                            <ListItemButton
                                sx={{ pl: 6 }}
                                selected={sideNavBarSelectedItemIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemIcon>
                                    <ListIcon />
                                </ListItemIcon>
                                <ListItemText primary="Priorities" />
                            </ListItemButton>
                        </RouterLink>
                        <RouterLink to="/create-priority">
                            <ListItemButton
                                sx={{ pl: 6 }}
                                selected={sideNavBarSelectedItemIndex === 2}
                                onClick={(event) => handleListItemClick(event, 2)}
                            >
                                <ListItemIcon>
                                    <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Create Priority" />
                            </ListItemButton>
                        </RouterLink>
                        {/* <RouterLink to="/history">
                            <ListItemButton
                                sx={{ pl: 6 }}
                                selected={sideNavBarSelectedItemIndex === 3}
                                onClick={(event) => handleListItemClick(event, 3)}
                            >
                                <ListItemIcon>
                                    <HistoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="History" />
                            </ListItemButton>
                        </RouterLink> */}
                        <RouterLink to="/neti5-schedule">
                            <ListItemButton
                                sx={{ pl: 6 }}
                                selected={sideNavBarSelectedItemIndex === 5}
                                onClick={(event) => handleListItemClick(event, 5)}
                            >
                                <ListItemIcon>
                                    <CalendarMonthIcon />
                                </ListItemIcon>
                                <ListItemText primary="Neti5 Schedule" />
                            </ListItemButton>
                        </RouterLink>
                    </List>
                </Collapse>
                <RouterLink to="/search">
                <ListItemButton
                    selected={sideNavBarSelectedItemIndex === 6}
                    onClick={(event) => handleListItemClick(event, 6)}
                >
                    <ListItemIcon>
                        <InsertEmoticonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Vibe" />
                    {sideNavVibeOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                </RouterLink>
                <Collapse in={sideNavVibeOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <RouterLink to="/search">
                            <ListItemButton
                                sx={{ pl: 6 }}
                                selected={sideNavBarSelectedItemIndex === 4}
                                onClick={(event) => handleListItemClick(event, 4)}
                            >
                                <ListItemIcon>
                                    <SearchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Explore" />
                            </ListItemButton>
                        </RouterLink>
                    </List>
                </Collapse>
                <RouterLink to="/insights">
                <ListItemButton
                    selected={sideNavBarSelectedItemIndex === 7}
                    onClick={(event) => handleListItemClick(event, 7)}
                >
                    <ListItemIcon>
                        <SelfImprovementIcon />
                    </ListItemIcon>
                    <ListItemText primary="Flow" />
                    {sideNavFlowOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                </RouterLink>
                <Collapse in={sideNavFlowOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <RouterLink to="/insights">
                            <ListItemButton
                                sx={{ pl: 6 }}
                                selected={sideNavBarSelectedItemIndex === 12}
                                onClick={(event) => handleListItemClick(event, 12)}
                            >
                                <ListItemIcon>
                                    <InsightsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Insights" />
                            </ListItemButton>
                        </RouterLink>
                    </List>
                </Collapse>
            </List>
            <Divider />
            <List>
                <ListItemButton
                    selected={sideNavBarSelectedItemIndex === 8}
                    onClick={(event) => handleListItemClick(event, 8)}
                >
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                </ListItemButton>
                <ListItemButton
                    selected={sideNavBarSelectedItemIndex === 9}
                    onClick={(event) => handleListItemClick(event, 9)}
                >
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                </ListItemButton>
                <ListItemButton
                    selected={sideNavBarSelectedItemIndex === 10}
                    onClick={(event) => handleListItemClick(event, 10)}
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItemButton>
                <ListItemButton
                    selected={sideNavBarSelectedItemIndex === 11}
                    onClick={(event) => handleListItemClick(event, 11)}
                >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </div>
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={sideNavBarMobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default SideNavBar;
