import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CreatePrior from "./components/CreatePrior";
import { Box, Stack } from "@mui/material";
import MainNavBar from "./components/navbar/MainNavBar";
import SideNavBar from "./components/navbar/SideNavBar";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { Suspense, useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth } from "aws-amplify";
import aws_exports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import theme from "./theme";
import "../src/index.css";
import { Hub } from "aws-amplify";
import useLoggedInUser from "./hooks/UseLoggedInUser";
import ListPrioritiesPage from "./pages/PrioritiesPage";
import NotificationsPage from "./pages/NotificationsPage";
import NetiBuddyPage from "./pages/NetiBuddyPage";
import { API_BASE_LINK } from "./api/main";
import HistoricalPrioritiesPage from "./pages/HistoricalPrioritiesPage";
import UseWebsocketConnection from "./utils/WebsocketUtils";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WS_BASE_LINK } from "./api/main";
import useNotificationApi from "./hooks/UseNotificationApi";
import usePriorityApi from "./hooks/UsePriorityApi";
import useTopicsApi from "./hooks/UseTopicsApi";
import useUsersApi from "./hooks/UseUsersApi";
import usePriorityUtils from "./utils/PriorityUtils";
import SearchPage from "./pages/SearchPage";
import Neti5SchedulePage from "./pages/Neti5SchedulePage";
import { useRecoilState } from "recoil";
import { loggedInUserAtom } from "./recoil/user";
import useChatApi from "./hooks/UseChatApi";
import InsightsPage from "./pages/InsightsPage";

Amplify.configure(aws_exports);



const App = () => {
    const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserAtom);
    // const { loggedInUser, setLoggedInUser } = useLoggedInUser();

    const { fetchTopics } = useTopicsApi();

    const { fetchUsers } = useUsersApi();

    const { fetchYourPriorities, fetchBucketListPriorities, fetchPriorityRequests, fetchHistoricalPriorities } = usePriorityApi();

    const { fetchNotifications } = useNotificationApi();

    const { sendNotifications, subscribeNotifications, isSocketLive, setIsSocketLive } = UseWebsocketConnection();

    const sendN = (users, client) => {
        console.log("sending notifications to:", users);
        client.send(JSON.stringify({
            action: "sendNotifications",
            recipients: users
        }));
    };

    // const [client, setClient] = useState(socket);
    const {client, setClient} = usePriorityUtils()

    const setupClient = async () => {
        const socket = await new W3CWebSocket(WS_BASE_LINK);
        setClient(socket);
        subscribeNotifications(client);
    };

    const { fetchChatMessages, fetchSystemPrompt } = useChatApi();

    useEffect(() => {
        console.log("useEffect from App.tsx");
        console.log("loggedInUser is:", loggedInUser);
        fetchYourPriorities(() => { });
        sendUserInfoToBackend();
        subscribeNotifications(client);
        fetchBucketListPriorities();
        fetchPriorityRequests();
        fetchHistoricalPriorities();
        fetchTopics();
        fetchUsers();
        fetchNotifications();
        fetchSystemPrompt();
        fetchChatMessages();
    }, [loggedInUser]);

    async function sendUserInfoToBackend() {
        // Ensure we unsubscribe from all historical subscriptions and make a new subscription
        Hub.remove("auth", () => { });
        Hub.listen("auth", (data) => {
            if (data["payload"] && data["payload"]["event"] && data["payload"]["event"] === "signIn") {
                // Ensure we unsubscribe after we receive the required signIn event to clean up resources
                Hub.remove("auth", () => { });

                const email_id = data.payload.data.attributes.email;
                const name = data.payload.data.attributes.name;
                const tenant_id = 1;
                invokePostUserApi(email_id, name, tenant_id);
                setupClient();
            }
        });
    }

    async function invokePostUserApi(email_id: string, name: string, tenant_id: number) {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        console.log("token:", token);
        const userId = user && user["username"];
        const username = user && user.attributes && user.attributes["name"];
        const currentUserId: string = user.attributes["email_id"];
        if (userId && username) setLoggedInUser({ userId, username, email_id });
        console.log("user API is: ", user);
        console.log("userId: ", userId);
        console.log("username: ", username);
        console.log("Token is: ", JSON.stringify(token));
        console.log("email_id is: ", email_id);

        fetch(API_BASE_LINK + "api/user/", {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: email_id,
                label: name,
                tenant: tenant_id,
            }),
        })
            .then((response) => console.log("Response: " + JSON.stringify(response)))
            .then((json) => console.log("Response json: " + json))
            .catch((err) => console.log("Response error: " + err));
    };

    return (

        <AmplifyProvider theme={theme}>
            <Authenticator loginMechanisms={["email"]} signUpAttributes={["email", "name"]}>
                {({ signOut, user }) => (
                    <BrowserRouter>
                        {user && user.attributes && user.attributes["name"] && signOut && (
                            <Box>
                                <MainNavBar username={user.attributes["name"]} signOut={signOut} client={client} />
                                <Stack direction={"row"}>
                                    <SideNavBar username={user.attributes["name"]} signOut={signOut} />
                                    
                                    <Routes>
                                        <Route path="/" element={<ListPrioritiesPage client={client} />} />
                                        {/* <Route path="/history" element={<HistoricalPrioritiesPage />} /> */}
                                        <Route
                                            path="/create-priority"
                                            element={
                                                <CreatePrior currentUserId={user.attributes["email"]} username={user.attributes["name"]} signOut={signOut} client={client} />
                                            }
                                        />
                                        <Route path="/notifications" element={<NotificationsPage onNotificationPush={sendN} />} />
                                        <Route path="/search" element={<SearchPage client={client}/>} />
                                        <Route path="/neti5-schedule" element={<Neti5SchedulePage />} />
                                        <Route path="/buddy" element={<NetiBuddyPage />} />
                                        <Route path="/insights" element={<InsightsPage />} />
                                    </Routes>
                                    
                                </Stack>
                            </Box>
                        )}
                        ;
                    </BrowserRouter>
                )}
            </Authenticator>
        </AmplifyProvider>

    );
};

export default App;
