import { Grid, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { Divider } from '@aws-amplify/ui-react';
import Avatar from 'react-avatar';
import Box from '@mui/material/Box';

interface CommentProps {
    id: number;
    comment_text: string;
    creation_time: string;
    creator: string;
};

const CommentCard = (props: CommentProps) => {
    return <Grid container direction="row" item xs={12} sx={{ mt: 0 }} justifyContent="center">
        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
            <Divider />
        </Grid>
        <Grid item xs={2}>

            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={12}><Avatar name={props.creator} round="10px" size='50' color="#1976d3"/></Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "numeric",
                            day: "2-digit"
                        }).format(new Date(props.creation_time))}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {new Intl.DateTimeFormat("en-GB", {
                            hour: "numeric",
                            minute: "numeric"
                        }).format(new Date(props.creation_time))}
                    </Typography>
                </Grid>
            </Grid>

        </Grid>
        <Grid item xs={10} sx={{ p: 1 }}>
            <Box 
                sx={{
                    backgroundColor: 'lightgray',
                    height: '100%',
                    width: '100%',
                    borderRadius: "10px"
                }}
            >
                <Typography variant="body1" padding="10px">
                    {props.comment_text}
                </Typography>
            </Box>
        </Grid>
    </Grid>
};

const theme = createTheme({
    typography: {
        body2: {
            fontSize: 6,
            fontStyle: 'italic'
        },
        body1: {
            fontSize: 8
        }
    }
});

export default CommentCard;