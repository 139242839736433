import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

const container: HTMLElement = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <RecoilRoot>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <App />
    </LocalizationProvider>
  </RecoilRoot>
);

reportWebVitals();