import React from "react";
import { Link } from "react-router-dom";

export interface RouterLinkProps {
    to: string;
    children: React.ReactNode;
}

const RouterLink: React.FC<RouterLinkProps> = ({ to, children }) => {
    return (
        <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
            {children}
        </Link>
    );
};

const RouterIconLink: React.FC<RouterLinkProps> = ({ to, children }) => {
    return (
        <Link to={to} style={{ color: "inherit" }}>
            {children}
        </Link>
    );
};

export { RouterLink, RouterIconLink };
