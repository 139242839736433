import { List, ListItem } from "@mui/material";

interface StaticListProps<T extends { id: number }> {
    items: T[];
    renderItem: (item: T, index: number) => React.ReactNode;
}

const StaticList = <T extends { id: number }>(props: StaticListProps<T>) => {
    return (
        <List>
            {props && props.items && Array.isArray(props.items) ?
                props.items.map((item: T, index: number) => {
                    return (
                        <ListItem alignItems="flex-start" key={index}>
                            {props.renderItem(item, index)}
                        </ListItem>
                    );
                })
                : <div/>
            }
        </List>
    );
};

export default StaticList;
