import * as Either from "fp-ts/lib/Either";
import * as Option from "fp-ts/lib/Option";
import { useState, useEffect } from "react";
import { CreatePriorityLabelModel } from "../recoil/topic";
import { CreatePriorityModel, createPriorityModelSchema } from "../recoil/priority";
import { TopicMapModel, UserTagMapModel } from "../recoil/priority";
import MentionOutput, { emptyMentionOutput } from "../components/mentioninput/MentionOutput";
import { validateZodSchema } from "../utils/ValidateZodSchema";
import useLoggedInUser from "./UseLoggedInUser";
import { Auth } from "aws-amplify";
import moment from "moment";

const priorityTypeItems = ["Main Priority", "Bucket List"];
const priorityScopeItems = ["Public", "Private"];
const lowMediumHighItems = ["Low", "Medium", "High"];
const levelOfEffortItems = lowMediumHighItems;

type FormItemState<T> = [T, React.Dispatch<React.SetStateAction<T>>, string | null, (error: string | null) => void];

const useFormItemState = <T>(initialValue: T): FormItemState<T> => {
    const [item, setItem] = useState<T>(initialValue);
    const [error, setError] = useState<string | null>(null);

    return [item, setItem, error, setError];
};

const useCreatePriorityForm = () => {
    const [creator, setCreator, creatorError, setCreatorError] = useFormItemState<string>("");
    // const [creator, setCreator] = "";
    const [title, setTitle, titleError, setTitleError] = useFormItemState<MentionOutput>(emptyMentionOutput);
    const [description, setDescription, descriptionError, setDescriptionError] =
        useFormItemState<MentionOutput>(emptyMentionOutput);
    const [date, setDate, dateError, setDateError] = useFormItemState<Date | null>(null);
    const [labels, setLabels, labelsError, setLabelsError] = useFormItemState<TopicMapModel[]>([]);
    const [usersTagged, setUsersTagged, setUsertagsError] = useFormItemState<UserTagMapModel[]>([]);
    const [type, setType, typeError, setTypeError] = useFormItemState<string>(priorityTypeItems[0]!);
    const [scope, setScope, scopeError, setScopeError] = useFormItemState<string>(priorityScopeItems[0]!);
    const [levelOfEffort, setLevelOfEffort, levelOfEffortError, 
        setLevelOfEffortError] = useFormItemState<string>(levelOfEffortItems[1]!);

    const { loggedInUser } = useLoggedInUser();

    useEffect(() => {
        console.log("useEffect from UserCreatePriorityForm.tsx");
        const getCurrentUserId = async () => {
            const currentUser = await Auth.currentAuthenticatedUser();
            const currentUserId = currentUser.attributes["email"];
            setCreator(currentUserId);
        };

        getCurrentUserId();
    }, []);

    const resetInputs = () => {
        setTitle(emptyMentionOutput);
        setDescription(emptyMentionOutput);
        setDate(null);
        setLabels([]);
        setUsersTagged([]);
    };

    const resetErrors = () => {
        setTitleError(null);
        setDescriptionError(null);
        setDateError(null);
        setLabelsError(null);
    };

    const resetForm = () => {
        resetInputs();
        resetErrors();
    };

    const getUserTagsFor = (input: string): string[] => {
        return input
            .split(" ")
            .filter((v) => v.startsWith("data-id"))
            .map((v) => v.replace(/"/g, "").split("=")[1])
            .filter((v): v is string => v !== undefined);
    };

    const getUserTags = (): string[] => {
        const userTags = getUserTagsFor(title.html);
        userTags.push(...getUserTagsFor(description.html));
        return userTags;
    };

    const onUserTagsChanged = (newUsertags: UserTagMapModel[]) => {
        console.log("New user tags from form:", newUsertags);
        setUsersTagged(newUsertags);
    };

    const onLabelChanged = (label: CreatePriorityLabelModel) => {
        console.log("New label selected/deselected from form:", label);

        setLabels((oldLabels) => {
            const newLabel = { topic: label.id, tenant: 1 };
            if (!oldLabels.some((item) => newLabel.topic === item.topic)) {
                const newLabels = [...oldLabels, newLabel];
                const validationResult = validateLabelsInput(newLabels);
                return validationResult ? newLabels : oldLabels;
            }

            const newLabels = oldLabels.filter((l) => l.topic !== newLabel.topic);
            validateLabelsInput(newLabels);
            return newLabels;
        });
    };

    const validateLabelsInput = (inputLabels: TopicMapModel[]): boolean => {
        const result = validateZodSchema(createPriorityModelSchema, {
            creator: creator,
            creation_time: moment().toISOString(),
            title: title.content,
            description: description.content,
            target_date: date,
            topics: inputLabels,
            state: "Active",
            usertags: usersTagged.map((v) => v.user),
            tenant: 1,
            priority_type: type,
            priority_scope: scope,
            level_of_effort: levelOfEffort
        });

        if (Either.isRight(result)) {
            setLabelsError(null);
            return true;
        }

        const errors = result.left;
        const labelsError = errors.topics?._errors?.at(0) || null;
        setLabelsError(labelsError);
        return labelsError === null;
    };

    const validateData = (): Option.Option<CreatePriorityModel> => {
        const result = validateZodSchema(createPriorityModelSchema, {
            creator: creator,
            creation_time: moment().toISOString(),
            title: title.content,
            description: description.content,
            target_date: date,
            topics: labels,
            state: "Active",
            usertags: getUserTags(),
            tenant: 1,
            priority_type: type,
            priority_scope: scope,
            level_of_effort: levelOfEffort 
        });

        if (Either.isRight(result)) {
            resetErrors();
            return Option.some(result.right);
        }

        const errors = result.left;
        setTitleError(errors.title?._errors?.at(0) || null);
        setDescriptionError(errors.description?._errors?.at(0) || null);
        setDateError(errors.target_date?._errors?.at(0) || null);
        setLabelsError(errors.topics?._errors?.at(0) || null);
        setTypeError(errors.priority_type?._errors?.at(0) || null);
        setScopeError(errors.priority_scope?._errors?.at(0) || null);
        setLevelOfEffortError(errors.level_of_effort?._errors?.at(0) || null);

        return Option.none;
    };

    const data = {
        title,
        description,
        date,
        labels,
        usertags: usersTagged,
        type,
        scope,
        levelOfEffort
    };

    const errors = {
        titleError,
        descriptionError,
        dateError,
        labelsError,
        typeError,
        scopeError,
        levelOfEffortError,
    };

    const events = {
        onTitleChange: setTitle,
        onDescriptionChange: setDescription,
        onUserTagsChanged: onUserTagsChanged,
        onDateChange: setDate,
        onLabelChanged: onLabelChanged,
        onTypeChanged: setType,
        onScopeChanged: setScope,
        onLevelOfEffortChanged: setLevelOfEffort,
        resetForm: resetForm,
        validate: validateData,
    };

    return {
        data,
        errors,
        events,
        usersTagged,
        priorityTypeItems,
        priorityScopeItems,
        levelOfEffortItems,
        setUsersTagged,
        onUserTagsChanged,
    };
};

export default useCreatePriorityForm;
