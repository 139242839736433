import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Collaborators',
    "0": 0,
    "1": 4,
    "2": 3,
    "3+": 12,
  },
  {
    name: 'Self initiated',
    "0": 9,
    "1": 10
  },
  {
    name: 'Reschedules',
    "0": 9,
    "1": 3,
    "2": 1,
    "3+": 6,
  },
];

const MyResponsiveBar = (
    // { data /* see data tab */ }
    ) => (
      <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="0" stackId="a" fill="#8884d8" />
        <Bar dataKey="1" stackId="a" fill="#82ca9d" />
        <Bar dataKey="2" stackId="a" fill="#C981AF" />
        <Bar dataKey="3+" stackId="a" fill="#c9c880" />
        {/* <Bar dataKey="0" stackId="a" fill="#c4c2f2" />
        <Bar dataKey="1" stackId="a" fill="#c2f2d4" />
        <Bar dataKey="2" stackId="a" fill="#f2c2e0" />
        <Bar dataKey="3+" stackId="a" fill="#f2f1c2" /> */}
      </BarChart>
    </ResponsiveContainer>
)

export default MyResponsiveBar;