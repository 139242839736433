import { API_BASE_LINK } from "./main";

const UNREAD_NOTIF_API_URL: string = API_BASE_LINK + "api/notification/unread";
const ALL_NOTIF_API_URL: string = API_BASE_LINK + "api/notification";

export function getUnreadNotifications(token: string, currentUserId: string, setNotifications): void {
    fetch(UNREAD_NOTIF_API_URL+"?user_dummy="+currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {return response.json()})
        .then((data) => {
            console.log("Fetching Unread Notifications from API, response: " + JSON.stringify(data));
            setNotifications(data);
        })
        .catch((err) => console.log("Response error: " + err));
}

export function getAllNotifications(token: string, currentUserId: string, setAllNotifications): void {
    fetch(ALL_NOTIF_API_URL+"?user_dummy="+currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
        },
    })
    .then((response) => {return response.json()})
    .then((data) => {
        setAllNotifications(data);
        console.log("Fetching All Notifications from API, response: " + JSON.stringify(data));
    })
    .catch((err) => console.log("Response error: " + err));
};

export function putNotificationSeen(token: string, id: number): void {
    fetch(ALL_NOTIF_API_URL+ "/" + id.toString(), {
        method: "PUT",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ is_seen: true }),
    })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((err) => console.log("Response error: " + err));
};

function processRawNotificationResponse(json) {
    var notification_title = "";
    if (json["notification_type"] === "BuzzerReceived") {
        notification_title = `Buzzer from ${json["user"]} for Priority ${json["priority"]}`;
    } else if (json["notification_type"] === "PriorityNearingExpiry") {
        notification_title = `Priority ${json["priority"]} is nearing its target date!`;
    } else if (json["notification_type"] === "PriorityExpired") {
        notification_title = `Priority ${json["priority"]} has missed its target date!`;
    } else if (json["notification_type"] === "PriorityUpdate") {
        notification_title = `Priority ${json["priority"]} has has some updates!`;
    } else if (json["notification_type"] === "PriorityRequest") {
        notification_title = `New Priority request ${json["priority"]} from ${json["user"]}!`;
    } else {
        throw new Error("Unsuppoted otification type found: " + json["notification_type"]);
    }
    const final_output = {
        notification_type: json["notification_type"],
        title: notification_title,
        id: json["id"]
    };
    return final_output;
}

