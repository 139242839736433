import { getTopics, postNewTopic } from "../api/Topics";
import { atom, useRecoilState } from "recoil";
import { Auth } from "aws-amplify";
import * as Either from "fp-ts/lib/Either";
import { createPriorityLabelsAtom, CreatePriorityLabelModel } from "../recoil/topic";

const useTopicsApi = () => {
    const [createPriorityLabels, setCreatePriorityLabels] = useRecoilState(createPriorityLabelsAtom);

    const fetchTopics = async () => {
        console.log("Fetching topics from API");
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getTopics(token, currentUserId, setCreatePriorityLabels);
    };

    const callNewTopicApi = async (topic_name: string) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        if(createPriorityLabels.filter(x => x.topic_name === topic_name).length === 0) {
            postNewTopic(token, currentUserId, topic_name, createPriorityLabels, setCreatePriorityLabels);
        } else {
            console.log("Topic is already available hence not creating a duplicate entry for: " + topic_name);
        }
    }

    const onAddLabel = (label: CreatePriorityLabelModel): Either.Either<string, CreatePriorityLabelModel> => {
        callNewTopicApi(label.topic_name);
        return Either.right(label);
    };

    const getTopicsFromIds = (ids: number[]): CreatePriorityLabelModel[] => {
        return ids
            .map((id) => createPriorityLabels.find((p) => p.id == id))
            .filter((p): p is CreatePriorityLabelModel => p !== undefined);
    };

    return {
        createPriorityLabels,
        setCreatePriorityLabels,
        fetchTopics,
        getTopicsFromIds,
        onAddLabel,
    };
};

export default useTopicsApi;
