import { Box, Card, Grid, Alert, Stack, Toolbar, Typography } from "@mui/material";
import React, { useEffect } from "react";
import useNotificationApi from "../hooks/UseNotificationApi";
import { NotificationModel, NotificationType } from "../recoil/notification";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import { useNavigate } from "react-router-dom";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

interface NotificationsPageProps {
    onNotificationPush: Function
}

const NotificationsPage: React.FC<NotificationsPageProps> = (props) => {
    const { notifications, setNotifications, allNotifications, setAllNotifications, flagNotificationSeen, fetchNotifications } = useNotificationApi();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("useEffect from NotificationsPage.tsx");
        // fetchNotifications();
    }, []);

    const navigateToPriorityRequestsTab = (notification) => {
        console.log(notification.priority)
        navigate({
            pathname: "/",
            search: `?tabIndex=2&selectedId=${notification.priority.id}`,
        });
    };

    const onNotificationRemove = (id) => {
        setNotifications(notifications.filter((notification) => notification.id !== id))
    }

    const gotoYourPrioritiesAndShowDetails = (notification: NotificationModel) => {
        const pathlink = notification.priority.state === 'Closed' ? '/history' : '/'
        const tabIndex = notification.priority.priority_type === 'Main Priority' ? 0 : 1
        const searchlink = pathlink === '/history' ? `?selectedId=${notification.priority.id}` : `?tabIndex=${tabIndex}&selectedId=${notification.priority.id}`
        navigate({
            pathname: pathlink,
            search: searchlink,
        }) ;
    };

    const NoNewNotifications = () => {
            return (
                // <div>  
                        <p> {notifications.length===0 ? "No new notifications" : ""} </p>
                // </div>
                );
    };

    const onNotificationClick = (notification: NotificationModel) => {
        // fetchNotifications();
        switch (notification.notification_type) {
            case "PriorityRequest":
                flagNotificationSeen(notification.id);
                navigateToPriorityRequestsTab(notification);
                onNotificationRemove(notification.id);
                break;
            default:
                flagNotificationSeen(notification.id);
                gotoYourPrioritiesAndShowDetails(notification);
                onNotificationRemove(notification.id);
                break;
        }
    };

    return (
        <Box width={"100%"} px={4} py={4}>
            <Toolbar />
            <Typography variant="h5" mb={4} sx={{ fontWeight: "bold" }}>
                Notifications
            </Typography>
            {notifications.length === 0 ? <NoNewNotifications /> :
            <Box px={2}>
                {notifications.map((notification) => {
                    return (
                        <Grid key={notification.id} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={8} xs={12} sx={{ padding: "0!important" }}>
                                <Card
                                    key={notification.id}
                                    onClick={() => onNotificationClick(notification)}
                                    sx={{
                                        display: "flex",
                                        marginBottom: "12px",
                                        paddingX: "8px",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    <Stack
                                        direction={"column"}
                                        flexGrow={1}
                                        sx={{
                                            padding: "10px 20px",
                                        }}
                                    >
                                        <Stack justifyContent="space-around" direction={"row"} alignItems={"center"}>
                                            <NotificationIcon notificationType={notification.notification_type} notification={notification}/>
                                            <NotificationCard notification={notification} notificationType={notification.notification_type}/>
                                        </Stack>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item md={4} xs={12} />
                        </Grid>
                    );
                })}
            </Box>}
            <Typography variant="h6" mb={4} sx={{ fontWeight: "bold" }}>
                Past notifications
            </Typography>
            <Box px={2}>
                {allNotifications.map((notification) => {
                    return (
                        <Grid key={notification.id} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={8} xs={12} sx={{ padding: "0!important" }}>
                                <Card
                                    key={notification.id}
                                    onClick={() => onNotificationClick(notification)}
                                    sx={{
                                        display: "flex",
                                        marginBottom: "12px",
                                        paddingX: "8px",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    <Stack
                                        direction={"column"}
                                        flexGrow={1}
                                        sx={{
                                            padding: "10px 20px",
                                        }}
                                    >
                                        <Stack justifyContent="space-around" direction={"row"} alignItems={"center"}>
                                            <NotificationIcon notificationType={notification.notification_type} notification={notification}/>
                                            <NotificationCard notification={notification} notificationType={notification.notification_type}/>
                                        </Stack>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item md={4} xs={12} />
                        </Grid>
                    );
                })}
            </Box>
        </Box>
    );
};

interface NotificationIconProps {
    notificationType: NotificationType;
    notification: NotificationModel;
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({ notificationType, notification }) => {
    const getIcon = (): React.ReactNode => {
        switch (notificationType) {
            case "PriorityRequest":
                return <AssignmentIndIcon fontSize="large" color="primary"/>;
            case "PriorityRequestAccepted":
                return <DoneOutlineIcon fontSize="large" color="success"/>;
            case "PriorityRequestRejected":
                return <ErrorOutlineIcon fontSize="large" color="error"/>;
            case "PriorityToMain":
                return <DoneOutlineIcon fontSize="large" color="success"/>;
            case "PriorityToBucket":
                return <AssignmentReturnedIcon fontSize="large" color="warning"/>;
            case "PriorityPaused":
                return <PauseCircleFilledIcon fontSize="large" color="warning"/>;
            case "PriorityResumed":
                return <PlayCircleFilledIcon fontSize="large" color="success"/>;
            case "PriorityOrderUp":
                return <ArrowUpwardIcon fontSize="large" color="success"/>;
            case "PriorityOrderDown":
                return <ArrowDownwardIcon fontSize="large" color="warning"/>;
            case "PriorityClosed":
                return <TaskAltIcon fontSize="large" color="primary"/>
            case "PriorityMissed":
                return <AssignmentLateIcon fontSize="large" />;
            case "PriorityNearingDeadLine":
                return <AssignmentReturnIcon fontSize="large" />;
            case "BuzzerReceived":
                return <SpatialAudioIcon fontSize="large" color="primary"/>;
            default:
                return <div></div>;
        }
    };
    

    return (
        <Box mr={3} color="gray">
            {getIcon()}
        </Box>
    );
};

interface NotificationCardProps {
    notificationType: NotificationType;
    notification: NotificationModel;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({ notificationType, notification }) => {
    var creator = notification.creator?.split('@')[0]
    creator = (creator==="dummy")?"Anantha.Krishna":creator
    const getCard = (): React.ReactNode => {
        switch (notificationType) {
            case "PriorityRequest":
                var text_content = <div>New Priority request from <b>{creator}</b> with title "<i>{notification.priority.title}</i>"</div>;
                return text_content;
            case "PriorityRequestAccepted":
                text_content = <div><b>{creator}</b> accepted your collaboration request for priority with title "<i>{notification.priority.title}</i>" </div>;
                return text_content;
            case "PriorityRequestRejected":
                text_content = <div><b>{creator}</b> rejected your collaboration request for priority with title "<i>{notification.priority.title}</i>" </div>;
                return text_content;
            case "PriorityToMain":
                text_content = <div><b>{creator}</b> moved the priority with title "<i>{notification.priority.title}</i>" to top 5</div>;
                return text_content;
            case "PriorityToBucket":
                text_content = <div><b>{creator}</b> moved the priority with title "<i>{notification.priority.title}</i>" to bucket list</div>;
                    return text_content;
            case "PriorityPaused":
                text_content = <div><b>{creator}</b> paused the priority with title "<i>{notification.priority.title}</i>" </div>;
                return text_content;
            case "PriorityResumed":
                text_content = <div><b>{creator}</b> resumed the priority with title "<i>{notification.priority.title}</i>" </div>;
                    return text_content;
            case "PriorityOrderUp":
                text_content = <div><b>{creator}</b> moved up the priority "<i>{notification.priority.title}</i>"</div>;
                    return text_content;
            case "PriorityOrderDown":
                text_content = <div><b>{creator}</b> pushed down the priority "<i>{notification.priority.title}</i>"</div>;
                    return text_content;
            case "PriorityClosed":
                text_content = <div><b>{creator}</b> closed the priority "<i>{notification.priority.title}</i>"</div>;
                    return text_content;
            case "PriorityMissed":
                return <AssignmentLateIcon fontSize="large" />;
            case "PriorityNearingDeadLine":
                return <AssignmentReturnIcon fontSize="large" />;
            case "BuzzerReceived":
                text_content = <div>Buzzer: <b>{creator}</b> seeks attention on the priority "<i>{notification.priority.title}</i>" </div>;
                return text_content;

            default:
                return <div></div>;
        }
    };
    

    return (
        <Typography variant="h6" align="left" sx={{width:"min-content", flexGrow:1}}>
        {getCard()}
        </Typography>

    );
};

export default NotificationsPage;
