import { string } from "fp-ts";
import { API_BASE_LINK } from "./main";
import { Auth } from "aws-amplify";

const GET_REQUESTS_API_URL: string = API_BASE_LINK + "api/priorityrequest"
const PUT_REQUESTS_API_URL: string = API_BASE_LINK + "api/priorityrequest"

export function getPriorityRequests(token: string, currentUserId: string, setPriorityRequests): void {
    fetch(GET_REQUESTS_API_URL+"?user_dummy="+currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => {return response.json()})
        .then((data) => {
            setPriorityRequests(data);
            console.log("Fetching Your Priority requests from API, response: " + JSON.stringify(data));
        })
        .catch((err) => console.log("Response error: " + err));
};

export function putPriorityRequests(request, token: string, currentUserId: string): void {
    fetch(PUT_REQUESTS_API_URL+"/"+request.id, {
        method: "PUT",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({"request_status": request.request_status,
                                "target_priority": request.target_priority})
    })
        .then((response) => response.json())
        // .then((data) => {setYourPriorities(data)})
        .catch((err) => console.log("Response error: " + err));
    

};

// Newer version to set atoms by default

export async function getPriorityRequestsApi() {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentUserId = currentUser.attributes["email"];
    const token = currentUser.signInUserSession.idToken.jwtToken;
    const response = await fetch(GET_REQUESTS_API_URL+"?user_dummy="+currentUserId, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })
    const json = await response.json();
    return json
};