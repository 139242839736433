import { Avatar, Box, Button, Dialog, DialogContent, Grid, IconButton, Stack, Typography, Tooltip } from "@mui/material";
import { ListPriorityModel, ListPriorityBaseModel } from "../recoil/priority";
import { HorizontalSpacer, VerticalSpacer } from "./Spacer";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { getColorFromTime, getLabelColorForIndex } from "../utils/PriorityUtils";
import moment from "moment";
import PriorityLabelSingle from "./PriorityLabelSingle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PauseIcon from "@mui/icons-material/Pause";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PublicIcon from "@mui/icons-material/Public";
import LockIcon from "@mui/icons-material/Lock";
import { RecoilState, useRecoilValue } from "recoil";
import { prioritySelectedDetailItemAtom } from "../recoil/priority";
import { useEffect, useState } from "react";
import useTopicsApi from "../hooks/UseTopicsApi";
import { CreatePriorityLabelModel } from "../recoil/topic";
import AddCommentComponent from "./AddComment";
import ShowCommentsComponent from "./ShowComments";
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';

interface PriorityDetailPageProps {
    index: number;
    listAtom: RecoilState<ListPriorityModel[]>;
    priorityId: number;
    variant: string;
    onCloseDialog: () => void;
    onPlayPauseClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
    onBuzzerClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
    onMoveToBucketListPrioritiesClick: (event: React.MouseEvent, priority: ListPriorityModel) => void;
    onMoveToYourPrioritiesClick: (event: React.MouseEvent, priority: ListPriorityModel) => void;
    onClosePriorityClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
}

const PriorityDetailPage: React.FC<PriorityDetailPageProps> = ({
    index,
    listAtom,
    priorityId,
    variant,
    onCloseDialog,
    onPlayPauseClick,
    onBuzzerClick,
    onMoveToYourPrioritiesClick,
    onMoveToBucketListPrioritiesClick,
    onClosePriorityClick,
}) => {
    const priority = useRecoilValue(prioritySelectedDetailItemAtom({ listAtom, priorityId }));
    const [cachedIndex, _] = useState<number>(index);

    useEffect(() => {
        console.log("useEffect from PriorityDetailPage.tsx");
        if (priority === null) onCloseDialog();
    }, [priority]);

    const handleClose = (reason: string) => {
        onCloseDialog();
    };

    console.log("priority for detail page is:", priority);

    if (!priority) return <div></div>;

    return (
        <Dialog open fullWidth onClose={(_, reason: string) => handleClose(reason)}>
            <DialogContent dividers>
                <Stack direction={"column"}>
                    <TitleSection index={cachedIndex} priority={priority} onClosePriority={onCloseDialog} />
                    <VerticalSpacer height={8} />
                    <Stack direction={"row"} spacing={2}>
                        {priority.priority_scope==='Public' && <Tooltip title="Public Priority"><PublicIcon/></Tooltip>}
                        {priority.priority_scope==='Private' && <Tooltip title="Private Priority"><LockIcon/></Tooltip>}
                        <PriorityDate priority={priority} />
                    </Stack>
                    <VerticalSpacer height={12} />
                    {priority.topics.length !== 0 && (
                        <>
                            <Header text="Labels" />
                            <LabelsSection priority={priority} />
                            <VerticalSpacer height={16} />
                        </>
                    )}
                    <CollaboratorsSection priority={priority.source_priority?priority.source_priority:priority} isCreator={true}/>
                    <Header text="Description" />
                    <Typography sx={{ fontSize: 18 }}>{priority.description}</Typography>
                    <VerticalSpacer height={24} />
                    {variant==='full'&&(<PriorityActions
                        priority={priority}
                        onPlayPauseClick={onPlayPauseClick}
                        onBuzzerClick={onBuzzerClick}
                        onMoveToYourPrioritiesClick={onMoveToYourPrioritiesClick}
                        onMoveToBucketListPrioritiesClick={onMoveToBucketListPrioritiesClick}
                        onClosePriorityClick={onClosePriorityClick}
                    />)}
                    <AddCommentComponent priorityId={priorityId}/>
                    <ShowCommentsComponent priorityId={priorityId}/>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

const Header: React.FC<{ text: string }> = ({ text }) => {
    return <Typography sx={{ fontSize: 14, marginBottom: "12px" }}>{text}</Typography>;
};

interface TitleSectionProps {
    index: number;
    priority: ListPriorityModel;
    onClosePriority: () => void;
}

const TitleSection: React.FC<TitleSectionProps> = (props) => {
    return (
        <Stack direction={"row"}>
            <Avatar
                sx={{
                    bgcolor: getColorFromTime(props.priority),
                    marginRight: 2,
                    width: "35px",
                    height: "35px",
                    fontSize: "18px",
                    color: "black",
                    fontWeight: "600"
                }}
                style={{ alignItems: "center" }}
            >
                {props.index + 1}
            </Avatar>
            <Typography
                variant="h6"
                align="left"
                sx={{
                    width: "min-content",
                    flexGrow: 1,
                    margin: "0 2",
                }}
            >
                {props.priority.title}
            </Typography>
            <IconButton onClick={props.onClosePriority}>
                <CloseIcon />
            </IconButton>
        </Stack>
    );
};

const PriorityDate: React.FC<{ priority: ListPriorityModel }> = ({ priority }) => {
    const date = moment(priority.target_date);
    const formattedDate = date.format("MMM DD, YYYY");
    const relativeDate = date.fromNow();
    const dateUiText = `${formattedDate} (${relativeDate})`;

    return (
        <Typography
            noWrap
            sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: "gray",
            }}
        >
            {dateUiText}
        </Typography>
    );
};

const LabelsSection: React.FC<{ priority: ListPriorityModel }> = ({ priority }) => {
    const { getTopicsFromIds } = useTopicsApi();

    const [topics, setTopics] = useState<CreatePriorityLabelModel[]>([]);
    useEffect(() => {
        console.log("useEffect 2 from PriorityDetailPage.tsx");
        setTopics(getTopicsFromIds(priority.topics.map((t) => t.topic)));
    }, [priority.topics]);

    return (
        <Grid container rowSpacing={1} columnSpacing={1}>
            {topics && topics.map((topic, index) => {
                return (
                    <Grid item key={index}>
                        <PriorityLabelSingle
                            label={topic}
                            isSelected={false}
                            onLabelSelected={(_) => {}}
                            backgroundColor={getLabelColorForIndex(index)}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

const CollaboratorsSection: React.FC<{ priority: ListPriorityModel | ListPriorityBaseModel, isCreator: Boolean }> = ({ priority, isCreator }) => {
    const colorWithOpacity = (hexColor: string, opacity: number): string => {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return hexColor + _opacity.toString(16).toUpperCase();
    };

    const getIconColor = (status: string): string => {
        switch (status) {
            case "Accepted":
                return "#4CAF50";
            case "Main Priority":
                return "#4CAF50";
            case "Rejected":
                return "#F44336";
            case "Bucket List":
                return "#FFEB3B";
            default:
                return "#607D8B";
        }
    };

    const getColor = (status: string): string => {
        switch (status) {
            case "Accepted":
                return "#c2f2c3";
            case "Main Priority":
                return "#c2f2c3";
            case "Rejected":
                return "#f2c2cb";
            case "Bucket List":
                return "#f2e6c2";
            default:
                return "#607D8B";
        }
    };

    const orderIcons = [
        <LooksOneIcon/>,
        <LooksTwoIcon/>,
        <Looks3Icon/>,
        <Looks4Icon/>,
        <Looks5Icon/>
    ];

    // const orderIcons = [
    //     <Filter1Icon/>,
    //     <Filter2Icon/>,
    //     <Filter3Icon/>,
    //     <Filter4Icon/>,
    //     <Filter5Icon/>
    // ];

    const getIcon = (status: string, state: string, order: number): React.ReactNode => {
        const colorByStatus = getIconColor(status);
        const defaultColor = "#9E9E9E";
        switch (status) {
            case ("Accepted" ):
              return state==="Active"?
                <Box>
                <DoneIcon htmlColor={colorByStatus} fontSize={"small"}/>
                {orderIcons[order-1]}
                </Box>:
                state==="Paused"?
                <Box>
                <PauseIcon htmlColor={"red"} fontSize={"small"} />
                {orderIcons[order-1]}
                </Box>
                :
                <TaskAltIcon htmlColor={colorByStatus} fontSize={"small"}/>
            case ("Main Priority" ):
              return state==="Active"?
              <div>
              <DoneIcon htmlColor={colorByStatus} fontSize={"small"}/>
              {orderIcons[order-1]}
              </div>:
              state==="Paused"?
              <Box>
              <PauseIcon htmlColor={"red"} fontSize={"small"} />
              {orderIcons[order-1]}
              </Box>:
              <TaskAltIcon htmlColor={colorByStatus} fontSize={"small"}/>    
            case ("Rejected"):
              return <CloseIcon htmlColor={colorByStatus} fontSize={"small"} />;
            case ("Bucket List" ):
              return state==="Active"?<DeleteOutlineIcon htmlColor={defaultColor} fontSize={"small"}/>:state==="Paused"?<div><DeleteOutlineIcon htmlColor={defaultColor} fontSize={"small"}/><PauseIcon htmlColor={"red"} fontSize={"small"} /></div>:<div><DeleteOutlineIcon htmlColor={defaultColor} fontSize={"small"}/><CloseIcon htmlColor={"red"} fontSize={"small"}/></div>
            default:
              return <QuestionMarkIcon htmlColor={colorByStatus} fontSize={"small"} />;
          }
    };

    return (
        <>
            <>
                <Header text="Creator" />
                <Grid container rowSpacing={1} columnSpacing={1}>
                {(
                    
                    <Grid item key={0}>
                        
                        <Box
                            px={1}
                            py={0.5}
                            sx={{
                                background: colorWithOpacity(getColor(priority.priority_type), 0.35),
                                border: `1px solid ${getColor(priority.priority_type)}`,
                                borderRadius: "6px",
                            }}
                        >
                            <Stack direction={"row"} alignItems={"center"}>
                                {getIcon(priority.priority_type, priority.state, priority.priority_order)}
                                <HorizontalSpacer width={8} />
                                <Typography>{priority.creator}</Typography>
                            </Stack>
                        </Box>
                        <VerticalSpacer height={16} />
                        </Grid>
                )}
                </Grid>
            </>
            {priority.usertags.length ? (<>
            <Header text="Collaborators" />
            <Grid container rowSpacing={1} columnSpacing={1}>
                
                {priority.usertags.length && (priority.usertags.map((userTag, index) => {
                    const status = userTag.target_priority?userTag.target_priority.priority_type:userTag.request_status;
                    console.log("original status:", userTag.target_priority)
                    console.log("status:", status)
                        const state = userTag.target_priority?userTag.target_priority.state:"Active"
                    console.log("state:", state)
                    const order = userTag.target_priority?userTag.target_priority.priority_order:0;
                    const color = getColor(status);
                    return (
                        <Grid item key={index}>
                            
                            <Box
                                px={1}
                                py={0.5}
                                sx={{
                                    background: colorWithOpacity(color, 0.35),
                                    border: `1px solid ${color}`,
                                    borderRadius: "6px",
                                }}
                            >
                                <Stack direction={"row"} alignItems={"center"}>
                                    {getIcon(status, state, order)}
                                    <HorizontalSpacer width={8} />
                                    <Typography>{userTag.target_user}</Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    );
                }))}

            </Grid>
            <VerticalSpacer height={16} />
            </>): null}
        </>
    );
};

interface PriorityActionsProps {
    priority: ListPriorityModel;
    onPlayPauseClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
    onBuzzerClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
    onMoveToBucketListPrioritiesClick: (event: React.MouseEvent, priority: ListPriorityModel) => void;
    onMoveToYourPrioritiesClick: (event: React.MouseEvent, priority: ListPriorityModel) => void;
    onClosePriorityClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
}

const PriorityActions: React.FC<PriorityActionsProps> = ({
    priority,
    onPlayPauseClick,
    onBuzzerClick,
    onMoveToBucketListPrioritiesClick,
    onMoveToYourPrioritiesClick,
    onClosePriorityClick,
}) => {
    const onMovePriorityClick = (event: React.MouseEvent) => {
        if (priority.priority_type === "Main Priority") {
            onMoveToBucketListPrioritiesClick(event, priority);
        } else if (priority.priority_type === "Bucket List") {
            onMoveToYourPrioritiesClick(event, priority);
        }
    };

    return (
        <Grid container>
            <Grid item>
                <Button variant="outlined" onClick={(e) => onClosePriorityClick(e, priority)} sx={{ marginY: 1 }}>
                    Close Priority
                </Button>
            </Grid>
            <HorizontalSpacer width={8} />
            <Grid item>
                <Button variant="contained" onClick={onMovePriorityClick} sx={{ marginY: 1 }}>
                    {priority.priority_type === "Main Priority" ? "Move to Bucket List" : "Move to Your Priorities"}
                </Button>
            </Grid>
            <HorizontalSpacer width={8} />
            <Grid item>
                <Tooltip title="Play/Pause priority">
                <IconButton onClick={(e) => onPlayPauseClick(e, priority)}>
                    {priority.state === "Active" ? (
                        <PauseCircleFilledIcon style={{ fontSize: 32 }} />
                    ) : (
                        <PlayCircleFilledIcon style={{ fontSize: 32 }} />
                    )}
                </IconButton>
                </Tooltip>
                <Tooltip title="Send a buzz notification">
                <IconButton onClick={(e) => onBuzzerClick(e, priority)}>
                    <ElectricBoltIcon style={{ fontSize: 32 }} />
                </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default PriorityDetailPage;
