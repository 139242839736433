import { atom, RecoilState, selectorFamily } from "recoil";
import { ListPriorityModel, PriorityRequestModel, PriorityCommentsModel, ListCalendarEventModel } from "./model";
import { getBucketListPrioritiesApi, getHistoricalPrioritiesApi, getYourPrioritiesApi } from "../../api/Priority";
import { yourPrioritiesSelector } from "./selector";
import { getPriorityRequestsApi } from "../../api/Requests";

// Not used currently
export const currentPriorityAtom = atom<ListPriorityModel|null>({
    key: "currentPriorityAtom",
    default: null,
});

export const yourPrioritiesListAtom = atom<ListPriorityModel[]>({
    key: "prioritiesListAtom",
    default: [],
    // default: getYourPrioritiesApi(),
});

export const bucketListAtom = atom<ListPriorityModel[]>({
    key: "bucketListAtom",
    default: [], 
    // Parked for future update, we can shift to using default load in atom instead of App.tsx
    // default: getBucketListPrioritiesApi()
});

export const historicalPrioritiesAtom = atom<ListPriorityModel[]>({
    key: "historicalPrioritiesAtom",
    default: [],
    // default: getHistoricalPrioritiesApi(),
});

export const priorityRequestsAtom = atom<PriorityRequestModel[]>({
    key: "priorityRequestsAtom",
    default: [],
    // default: getPriorityRequestsApi(),
});

export const priorityCommentsAtom = atom<PriorityCommentsModel | null>({
    key: "priorityCommentsAtom",
    default: null,
});

export const searchPrioritiesAtom = atom<ListPriorityModel[]>({
    key: "searchPrioritiesAtom",
    default: [],
});

export const neti5edSchedulesAtom = atom<ListCalendarEventModel[]>({
    key: "neti5edSchedulesAtom",
    default: [],
});

export const currentSelectedPriorityAtom = atom<ListPriorityModel|null>({
    key: "currentSelectedPriorityAtom",
    default: null
})


export default currentPriorityAtom;