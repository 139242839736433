import React, { useEffect } from "react";
import usePriorityApi from "../hooks/UsePriorityApi";
import { yourPrioritiesListAtom, currentSelectedPriorityAtom } from "../recoil/priority";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Avatar, Box, Card, Grid, List, Menu, MenuItem, Stack, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import { ListPriorityModel } from "../recoil/priority";
import moment from "moment";
import ClosePriorityDialog from "./ClosePriorityDialog";
import PriorityDetailPage from "./PriorityDetailPage";
import usePriorityUtils, { getColorFromTime } from "../utils/PriorityUtils";
import DragAndDrop from "./DragAndDrop";
import AlertComponent from "./AlertComponent";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import CreatePriorityButton from "./CreatePriorityButton";

interface YourPrioritiesProps {
    selectedPriorityId?: number,
    client: any,
    // yourPriorities: ListPriorityModel[]
}

const YourPriorities: React.FC<YourPrioritiesProps> = (props) => {
    
    const yourPriorities = useRecoilValue(yourPrioritiesListAtom);

    const {
        onYourPriorityDragAndDropped,
    } = usePriorityApi();

    const [currentSelectedPriority, setCurrentSelectedPriority] = useRecoilState(currentSelectedPriorityAtom);

    const {setAlertOpen, onSuccess, onPlayPauseClick, onBuzzerClick, 
        onTaskDoneClick, onMoveToBucketListClick, onMoveToYourPrioritiesClick, closedPriority,
        alertOpen, alertSeverity, alertText } = usePriorityUtils();
    
    const theme = useTheme();
    const isSizeSmallOrAbove = useMediaQuery(theme.breakpoints.up("sm"));

    const navigate = useNavigate();

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setAlertOpen(false);
      };

    const onPrioritySelect = (priority: ListPriorityModel) => {
        if(currentSelectedPriority?.id!==priority.id){
            setCurrentSelectedPriority(priority);
        };
    };

    useEffect(() => {
        if (props.selectedPriorityId !== currentSelectedPriority?.id) {
            const _selectedPriority = yourPriorities && Array.isArray(yourPriorities)? 
            yourPriorities.find((p) => p.id == props.selectedPriorityId) : null;
            _selectedPriority && onPrioritySelect(_selectedPriority);
        };
        
    }, [props, yourPriorities]);


    var users = new Array();
    users[0] = "User1";

    return (
        <>
            <Box px={{ sm: 2, md: 4 }} py={4}>
                <CreatePriorityButton/>
                <DragAndDrop
                    droppableId="YourPriorities"
                    onDragEnd={onYourPriorityDragAndDropped}
                    onDragAndDropSuccess={onSuccess}
                    items={yourPriorities}
                    renderItem={(priority: ListPriorityModel, index: number) => {
                        return (
                            <Grid key={priority.title} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item md={8} xs={12} sx={{ padding: "0!important" }}>
                                    <Card
                                        key={priority.title}
                                        onClick={() => onPrioritySelect(priority)}
                                        sx={{ marginBottom: "12px", display: "flex" }}
                                    >
                                        <Stack
                                            direction={"column"}
                                            flexGrow={1}
                                            sx={{
                                                padding: "10px 20px",
                                            }}
                                        >
                                            <Stack
                                                justifyContent="space-around"
                                                direction={"row"}
                                                alignItems={"center"}
                                            >
                                                <div>
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: getColorFromTime(priority),
                                                            marginRight: 2,
                                                            width: "35px",
                                                            height: "35px",
                                                            fontSize: "18px",
                                                            color: "black",
                                                            fontWeight: "600"
                                                        }}
                                                        style={{ alignItems: "center" }}
                                                    >
                                                        {index + 1}
                                                    </Avatar>
                                                </div>
                                                <Typography
                                                    variant="h6"
                                                    align="left"
                                                    sx={{
                                                        width: "min-content",
                                                        flexGrow: 1,
                                                        margin: "0 2",
                                                    }}
                                                >
                                                    {priority.title}
                                                </Typography>
                                                {isSizeSmallOrAbove && (
                                                    <Stack direction={"column"} ml={2} alignItems={"center"}>
                                                        <PriorityDate priority={priority} />
                                                        <PriorityActions
                                                            priority={priority}
                                                            onPlayPauseClick={onPlayPauseClick}
                                                            onBuzzerClick={onBuzzerClick}
                                                            onTaskDoneClick={onTaskDoneClick}
                                                            onMoveToBucketListPrioritiesClick={onMoveToBucketListClick}
                                                        />
                                                    </Stack>
                                                )}
                                            </Stack>
                                            {!isSizeSmallOrAbove && (
                                                <Stack direction={"column"} ml={2} mt={2} alignItems={"center"}>
                                                    <PriorityDate priority={priority} />
                                                    <PriorityActions
                                                        priority={priority}
                                                        onPlayPauseClick={onPlayPauseClick}
                                                        onBuzzerClick={onBuzzerClick}
                                                        onTaskDoneClick={onTaskDoneClick}
                                                        onMoveToBucketListPrioritiesClick={onMoveToBucketListClick}
                                                    />
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Card>
                                </Grid>

                                <Grid item md={4} xs={12} />
                            </Grid>
                        );
                    }}
                />
                {closedPriority && (
                    <ClosePriorityDialog
                        priority={closedPriority}
                    />
                )}
                {currentSelectedPriority && (
                    <PriorityDetailPage
                        index={currentSelectedPriority.priority_order}
                        variant = 'full'
                        listAtom={yourPrioritiesListAtom}
                        priorityId={currentSelectedPriority.id}
                        onCloseDialog={() => {
                            navigate("/?tabIndex=0")
                            setCurrentSelectedPriority(null);
                        }}
                        onPlayPauseClick={onPlayPauseClick}
                        onBuzzerClick={onBuzzerClick}
                        onMoveToYourPrioritiesClick={onMoveToYourPrioritiesClick}
                        onMoveToBucketListPrioritiesClick={onMoveToBucketListClick}
                        onClosePriorityClick={onTaskDoneClick}
                    />
                )}
            </Box>
            <AlertComponent open={alertOpen} handleClose={handleAlertClose} severity={alertSeverity} alertText={alertText}/>
        </>
    );
};

interface PriorityDateProps {
    priority: ListPriorityModel;
}

const PriorityDate: React.FC<PriorityDateProps> = ({ priority }) => {
    const date = moment(priority.target_date);
    const formattedDate = date.format("MMM DD, YYYY");
    const relativeDate = date.fromNow();
    const dateUiText = `${formattedDate} (${relativeDate})`;

    return (
        <Typography
            noWrap
            sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: "gray",
            }}
        >
            {dateUiText}
        </Typography>
    );
};

interface PriorityActionsProps {
    priority: ListPriorityModel;
    onPlayPauseClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
    onBuzzerClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
    onTaskDoneClick: (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => void;
    onMoveToBucketListPrioritiesClick: (event: React.MouseEvent, priority: ListPriorityModel) => void;
}

const PriorityActions: React.FC<PriorityActionsProps> = ({
    priority,
    onPlayPauseClick,
    onBuzzerClick,
    onTaskDoneClick,
    onMoveToBucketListPrioritiesClick,
}) => {
    const [moreAnchorElement, setMoreAnchorElement] = React.useState<null | HTMLElement>(null);
    const isMoreOpen = Boolean(moreAnchorElement);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMoreAnchorElement(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        setMoreAnchorElement(null);
    };

    const handleMoreItemClick = (event: React.MouseEvent, index: number) => {
        event.stopPropagation();
        if (index === 0) onMoveToBucketListPrioritiesClick(event, priority);
        handleClose(event);
    };

    return (
        <Stack direction={"row"}>
            <Tooltip title="Play/Pause priority">
            <IconButton onClick={(e) => onPlayPauseClick(e, priority)}>
                {priority.state === "Active" ? (
                    <PauseCircleFilledIcon style={{ fontSize: 32 }} />
                ) : (
                    <PlayCircleFilledIcon style={{ fontSize: 32 }} />
                )}
            </IconButton>
            </Tooltip>
            <Tooltip title="Send a buzz notifiation">
            <IconButton onClick={(e) => onBuzzerClick(e, priority)}>
                <ElectricBoltIcon style={{ fontSize: 32 }} />
            </IconButton>
            </Tooltip>
            <Tooltip title="Close priority">
            <IconButton onClick={(e) => onTaskDoneClick(e, priority)}>
                <TaskAltIcon style={{ fontSize: 32 }} />
            </IconButton>
            </Tooltip>
            <Tooltip title="More options">
            <IconButton onClick={handleClick}>
                <MoreVertIcon style={{ fontSize: 32 }} />
            </IconButton>
            </Tooltip>
            <Menu
                anchorEl={moreAnchorElement}
                open={isMoreOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem onClick={(e) => handleMoreItemClick(e, 0)}>Move to Bucket List</MenuItem>
            </Menu>
        </Stack>
    );
};

export default YourPriorities;
