import { RecoilState, selector, selectorFamily } from "recoil";
import { activeChatSessionIndexAtom, yourChatsAtom } from "./atom";
import { MessageModel, chatSessionModel } from "./model";


// export const activeChatSessionSelector = selector<chatSessionModel>({
//     key: "activeChatSessionSelector",
//     get: ({get}) => get(yourChatsAtom.map((chat) => chat.find((p)=>p.sessionId==1)),
//     set: ({set}, newValue) => set(yourChatsAtom, newValue), 
// })

// export const activeChatSessionSelector = selector({
//     key: 'activeChatSessionSelector',
//     get: ({get}) => ({index}) {
//       const filter = get(activeChatSessionIndexAtom);
//       const list = get(yourChatsAtom);

//       return list
  
//       switch (filter) {
//         case 0:
//           return list.filter((item) => item.isComplete);
//         case 'Show Uncompleted':
//           return list.filter((item) => !item.isComplete);
//         default:
//           return list;
//       }
//     },
//   });

export const activeChatSessionSelector = selectorFamily<
    chatSessionModel | null,
    { listAtom : RecoilState<chatSessionModel[]>; activeIndex: number }
>({
    key: "activeChatSessionSelector",
    get:
        ({ listAtom: listState, activeIndex }) =>
        ({ get }) => {
            const list = get(listState);
            return list.find((e) => e.sessionId === activeIndex) ?? null;
        },
});


export const itemByIdSelector = selectorFamily({
    key: 'itemByIdSelector',
    get: (itemId) => ({ get }) => {
      const itemList = get(yourChatsAtom);
    //   const selectedItem = itemList.find(item => item.sessionId === itemId);
      const selectedItem = itemList[0]
      
      return selectedItem || null; // Return null if no item found with the given id
    },
  });
