import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import Neti5Schedule from "../components/Neti5Schedule";

function Neti5SchedulePage() {

    return (
        <Box width={"100%"} px={4} py={4}>
            <Toolbar />
            <Typography variant="h5" mb={0} sx={{ fontWeight: "bold" }}>
            Neti5 Your Schedule
            </Typography>
            <Neti5Schedule weeks={2} weekendsVisible={true}/>
        </Box>
    );
}

export default Neti5SchedulePage;
