import { useEffect } from "react";
import usePriorityApi from "../hooks/UsePriorityApi";
import { currentSelectedPriorityAtom, historicalPrioritiesAtom } from "../recoil/priority";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Card, Grid, Stack, useTheme } from "@mui/material";
import moment from "moment";
import { ListPriorityModel } from "../recoil/priority";
import DragAndDrop from "./DragAndDrop";
import usePriorityUtils from "../utils/PriorityUtils";
import PriorityDetailPage from "./PriorityDetailPage";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import StaticList from "./StaticList";
import CreatePriorityButton from "./CreatePriorityButton";

interface HistoricalPrioritiesProps {
    selectedPriorityId?: number
}



const HistoricalPriorities: React.FC<HistoricalPrioritiesProps> = (props) => {
    const historicalPriorities = useRecoilValue(historicalPrioritiesAtom);
    const [currentSelectedPriority, setCurrentSelectedPriority] = useRecoilState(currentSelectedPriorityAtom);

    const {
        onPlayPauseClick,
        onBuzzerClick,
        onMoveToBucketListClick,
        onMoveToYourPrioritiesClick,
        onTaskDoneClick } = usePriorityUtils();


    const navigate = useNavigate();

    const onPrioritySelect = (priority: ListPriorityModel) => {
        console.log("clicked priorityID:", priority.id)
        setCurrentSelectedPriority((currentPriority) => (currentPriority?.id === priority.id ? null : priority));
    };

    useEffect(() => {
        console.log("useEffect from HistoricalPriorities.tsx, historical priorities is: " + JSON.stringify(historicalPriorities));
        if (props.selectedPriorityId !== currentSelectedPriority?.id) {
            const _selectedPriority = Array.isArray(historicalPriorities) ?
                historicalPriorities.find((p) => p.id == props.selectedPriorityId) : null;
            _selectedPriority && onPrioritySelect(_selectedPriority);
        };
    }, [props, historicalPriorities]);

    const theme = useTheme();

    return (
        <Box px={{ sm: 2, md: 4 }} py={4}>
            <CreatePriorityButton/>
            <StaticList
                items={historicalPriorities}
                renderItem={(priority: ListPriorityModel, index: number) => {
                    return (
                        <Grid key={priority.title} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={8} xs={12} sx={{ padding: "0!important" }}>
                                <Card
                                    key={priority.title}
                                    onClick={() => onPrioritySelect(priority)}
                                    sx={{ marginBottom: "12px", display: "flex" }}
                                >
                                    <Stack
                                        direction={"column"}
                                        flexGrow={1}
                                        sx={{
                                            padding: "10px 20px",
                                        }}
                                    >
                                        <Stack
                                            justifyContent="space-around"
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <div>
                                                <Avatar
                                                    sx={{
                                                        // bgcolor: "#4caf50",
                                                        bgcolor: "#c2f2c3",
                                                        marginRight: 2,
                                                        width: "35px",
                                                        height: "35px",
                                                        fontSize: "18px",
                                                        color: "black",
                                                        fontWeight: "600"
                                                    }}
                                                    style={{ alignItems: "center" }}
                                                >
                                                    {index + 1}
                                                </Avatar>
                                            </div>
                                            <Typography
                                                variant="h6"
                                                align="left"
                                                sx={{
                                                    width: "min-content",
                                                    flexGrow: 1,
                                                    margin: "0 2",
                                                }}
                                            >
                                                {priority.title}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item md={4} xs={12} />
                        </Grid>
                    );
                }}
            />
            {currentSelectedPriority && (
                <PriorityDetailPage
                    index={currentSelectedPriority.priority_order - 1}
                    variant='minimal'
                    listAtom={historicalPrioritiesAtom}
                    priorityId={currentSelectedPriority.id}
                    onCloseDialog={() => {
                        // navigate("/history")
                        navigate("/?tabIndex=2")
                        setCurrentSelectedPriority(null);
                    }}
                    onPlayPauseClick={onPlayPauseClick}
                    onBuzzerClick={onBuzzerClick}
                    onMoveToYourPrioritiesClick={onMoveToYourPrioritiesClick}
                    onMoveToBucketListPrioritiesClick={onMoveToBucketListClick}
                    onClosePriorityClick={onTaskDoneClick}
                />
            )}
        </Box>
    );
}

export default HistoricalPriorities;
