import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React from "react";
import { CreatePriorityLabelModel } from "../recoil/topic";

interface Props {
    open: boolean;
    onClose: () => void;
    onAddLabel: (label: CreatePriorityLabelModel) => void;
}

const CreatePriorityAddLabelDialog: React.FC<Props> = (props: Props) => {
    const { open, onClose, onAddLabel } = props;

    const [label, setLabel] = React.useState<CreatePriorityLabelModel | null>(null);
    const [labelError, setLabelError] = React.useState<string | null>(null);

    const onAddClick = () => {
        if (label === null) {
            setLabelError("Label is required");
            return;
        }
        onAddLabel(label);
        onClose();
        setLabel(null);
        setLabelError(null);
    };

    const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLabel((oldLabel) => {
            if (oldLabel === null) {
                return {
                    id: 0,
                    topic_name: e.target.value
                };
            }
            return {
                ...oldLabel,
                topic_name: e.target.value,
            };
        });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
            <DialogTitle>Add Label</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    autoFocus
                    sx={{ display: "flex" }}
                    placeholder="New Label"
                    variant="outlined"
                    value={label?.topic_name ?? ""}
                    onChange={onLabelChange}
                    error={labelError !== null}
                    helperText={labelError}
                />
            </DialogContent>
            <DialogActions sx={{ marginX: "16px" }}>
                <Button onClick={onAddClick} variant="contained" disableElevation>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreatePriorityAddLabelDialog;
