import { API_BASE_LINK } from "./main";

const BUZZER_API_URL: string = API_BASE_LINK+ "api/buzzer/";

export function addBuzzer(token: string, priorityId: number, currentUserId: string, target_users: Array<string | undefined>, onSuccess): void {
    fetch(BUZZER_API_URL, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            priority: priorityId,
            originator_user: currentUserId
        }),
    })
        .then((response) => console.log("Response: " + JSON.stringify(response)))
        .then((json) => onSuccess(target_users))
        .catch((err) => console.log("Response error: " + err));
}

export function getBuzzers(token: string): void {
    fetch(BUZZER_API_URL, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => console.log("Response: " + JSON.stringify(response)))
        .then((json) => console.log("Response json: " + json))
        .catch((err) => console.log("Response error: " + err));
}
