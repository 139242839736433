import { EventInput } from '@fullcalendar/core'

function getTodayDateStr(): string {
  return new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
}

export const INITIAL_EVENTS: EventInput[] = [
  {
    id: "1",
    title: 'All-day event',
    start: getTodayDateStr()
  },
  {
    id: "2",
    title: 'Timed event',
    start: getTodayDateStr() + 'T12:00:00',
    end: getTodayDateStr() + 'T14:00:00',
  },
  {
    id: "3",
    title: 'New Timed event',
    start: getTodayDateStr() + 'T01:00:00',
    allDay: true
  }
]

export function createEventId(): string {
  return String(new Date().valueOf());
}