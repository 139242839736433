import { API_BASE_LINK } from "./main";

const GET_TOPICS_API_URL = API_BASE_LINK + "api/topics/";

export function getTopics(token: string, currentUserId: string, setCreatePriorityLabels): void {
    fetch(GET_TOPICS_API_URL, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => response.json())
        .then((data) => {
            setCreatePriorityLabels(data);
            console.log("Fetching Topics from API, response: " + JSON.stringify(data));
        })
        .catch((err) => console.log("Response error: " + err));
};

export function postNewTopic(
    token: string,
    currentUserId: string,
    topic_name: string,
    createPriorityLabels,
    setCreatePriorityLabels
): void {
    const addTopicRequestBody = JSON.stringify({ "topic_name": topic_name, "tenant": 1 });
    console.log("Add new topic request body:", addTopicRequestBody)
    fetch(GET_TOPICS_API_URL, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: addTopicRequestBody,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setCreatePriorityLabels((oldLabels) => {
                return [...oldLabels, { id: data.id, topic_name: data.topic_name }];
            });
            console.log("Add new topic response: " + JSON.stringify(data));
        })
        .catch((err) => console.log("Response error: " + err));
};