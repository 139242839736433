import { useRecoilState } from "recoil";
import { sideNavBarSelectedItemIndexAtom, sideNavBarMobileOpenAtom, sideNavBarOpenAtom,
        navBarMobileMoreAnchorElAtom, navBarAccountAnchorElAtom } from "../recoil/navbar";

export const drawerWidth = 240;

const useNavBar = () => {
    const [sideNavBarSelectedItemIndex, setSideNavBarSelectedItemIndex] = useRecoilState(sideNavBarSelectedItemIndexAtom);
    const [sideNavBarMobileOpen, setSideNavBarMobileOpen] = useRecoilState(sideNavBarMobileOpenAtom);
    const [sideNavBarOpen, setSideNavBarOpen] = useRecoilState(sideNavBarOpenAtom);
    const [navBarMobileMoreAnchorEl, setNavBarMobileMoreAnchorEl] = useRecoilState(navBarMobileMoreAnchorElAtom);
    const [navBarAccountAnchorEl, setNavBarAccountAnchorEl] = useRecoilState(navBarAccountAnchorElAtom);

    return {
        sideNavBarSelectedItemIndex,
        sideNavBarMobileOpen,
        sideNavBarOpen,
        navBarMobileMoreAnchorEl,
        navBarAccountAnchorEl,
        setSideNavBarSelectedItemIndex,
        setSideNavBarMobileOpen,
        setSideNavBarOpen,
        setNavBarMobileMoreAnchorEl,
        setNavBarAccountAnchorEl
    }
}

export default useNavBar;