import { API_BASE_LINK } from "./main";

const GET_USERS_API_URL: string = API_BASE_LINK + "api/user"

export function getNetiUsers(token: string, currentUserId: string, setUserTags): void {
    fetch(GET_USERS_API_URL, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => {return response.json()})
        .then((data) => {
            setUserTags(data);
            console.log("Fetching Users from API, response: " + JSON.stringify(data));
        })
        .catch((err) => console.log("Response error: " + err));
};