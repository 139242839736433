import { left } from "fp-ts/lib/EitherT";
import { parse } from "path";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WS_BASE_LINK } from "../api/main";
import { Auth } from "aws-amplify";
import { useState } from "react";
import useNotificationApi from "../hooks/UseNotificationApi";
import usePriorityApi from "../hooks/UsePriorityApi";


const UseWebocketConnection = () => {
    const { fetchNotifications } = useNotificationApi();
    const { fetchYourPriorities, fetchBucketListPriorities, fetchPriorityRequests, fetchHistoricalPriorities } = usePriorityApi();
    const [isSocketLive, setIsSocketLive] = useState<boolean|null>(null);

    const subscribeNotifications = async (client) => {
        // console.log("index of @ is", emailId.indexOf("@"));

        const currentUser = await Auth.currentAuthenticatedUser();
        var emailId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        // var emailId = email_id
        emailId = emailId.replaceAll(".", "DOT")
        emailId = emailId.replaceAll("@", "ATTHERATE")
        console.log("new email id for websocket", emailId);
        // const client = new W3CWebSocket(WS_BASE_LINK);
        console.log('WebSocket to be connected to:', client);

        client.onopen = () => {
            
            if (isSocketLive!==true) { 
                client.send(JSON.stringify({
                    action: "subscribe",
                    user_id: emailId
                }));
                setIsSocketLive(true);
                console.log('WebSocket Client Connected', client);
            }
            else { 
                setIsSocketLive(false);
                console.log('WebSocket Client Not Connected');
            };
        };

        client.onclose = () => {
            console.log('WebSocket Client DisConnected');
        };

        client.onmessage = async (message) => {
            console.log("Message received: ", message);
            console.log("Data in message:", message.data);
            // const currentUser = await Auth.currentAuthenticatedUser();
            // const currentUserId = currentUser.attributes["email"];
            // const token = currentUser.signInUserSession.idToken.jwtToken;
            fetchYourPriorities(() => {});
            fetchBucketListPriorities();
            fetchPriorityRequests();
            fetchHistoricalPriorities();
            fetchNotifications();
            
        };
        
        // console.log("type of client:", typeof(client));
    };

    const sendNotifications = (client, users: Array<string | undefined>) => {
        console.log("notification users raw:", users)
        users = users.map((user) => {
            const user2 = user?user.replaceAll(".", "DOT"):undefined;
            return user2?user2.replaceAll("@", "ATTHERATE"):undefined;
        });
        console.log("users to send notifications:", users);
        client.send(JSON.stringify({
            action: "sendNotifications",
            recipients: users
        }))
    };

    return {
        sendNotifications,
        subscribeNotifications,
        isSocketLive,
        setIsSocketLive,
    }
};
// export subscribeNotifications;
// export sendNotifications;

export default UseWebocketConnection;

