import { getNetiUsers } from "../api/Users";
import { Auth } from "aws-amplify";
import { useRecoilState } from "recoil";
import { userTagsAtom } from "../recoil/user";



const useUsersApi = () => {
    const [userTags, setUserTags] = useRecoilState(userTagsAtom);

    const fetchUsers = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getNetiUsers(token, currentUserId, setUserTags);
    };

    return {
        userTags,
        setUserTags,
        fetchUsers,
    };
};

export default useUsersApi;
