import React, { useEffect, useRef, useState } from 'react';
import { Container, Paper, TextField, List, ListItem, ListItemText, Button, Box, Grid, Typography, makeStyles, Toolbar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';
import { activeChatSessionSelector, yourChatsAtom } from '../recoil/chat';
import { activeChatSessionIndexAtom } from '../recoil/chat';
import { MessageModel, chatSessionModel } from '../recoil/chat';
import { IndexInfo } from 'typescript';
import { itemByIdSelector } from '../recoil/chat/selector';
import { getChatMessages, postChatMessage } from '../api/buddy';
import useChatApi from '../hooks/UseChatApi';
import SystemPromptInput from '../components/chat/SetSystemPrompt';
import { systemPromptAtom } from '../recoil/chat/atom';

const NetiBuddyPage = () => {

    //   const [messages, setMessages] = useState(["Hello"]); 

    const [messageInput, setMessageInput] = useState('');
    const [promptInput, setPromptInput] = useState('');
    const [chatSessions, setChatSessions] = useRecoilState(yourChatsAtom)

    //   const [activeChatSession, setActiveChatSession] = useState(chatSessions[0]) 

    const activeIndex = useRecoilValue(activeChatSessionIndexAtom);
    const chatSession = useRecoilValue(itemByIdSelector(0));
    const systemPrompt = useRecoilValue(systemPromptAtom);
    const [reply, setReply] = useState<MessageModel>({ "sender": "", "content": "", "timestamp": "" })
    const { fetchChatMessages, sendChatMessage, updateSystemPrompt, fetchSystemPrompt } = useChatApi();


    const handleTextChange = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage()
            setMessageInput('')
        }
    }

    const handlePromptTextChange = (e) => {
        if (e.key === 'Enter') {
            handleUpdatePrompt()
            setPromptInput('')
        }
    }

    const handleSendMessage = async () => {

        if (messageInput.trim() !== '') {
            sendChatMessage(messageInput)
            setMessageInput('');
        }
        return true
    };

    const handleUpdatePrompt = async () => {

        if (promptInput.trim() !== '') {
            updateSystemPrompt(promptInput);
            fetchSystemPrompt();
            setPromptInput('');
        }
        return true
    };

    const flexMapping = {

        0: 'flex-start',

        1: 'flex-end'

    }

    const bgColorMapping = {
        0: '#F4F1DE',
        1: '#F8EEEC'
    }

    const chatDisplayRef = useRef<HTMLDivElement | null>(null)

    const scrollToBottom = () => {
        chatDisplayRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        // if (chatDisplayRef.current) {
        //     chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
        // }
        scrollToBottom()
        // console.log("chatSession from useEffect:", chatSession)
    }, [chatSession]);

    return (

        <RecoilRoot>
            <Box width={"100%"} px={4} py={4}>
                <Toolbar />
                <Grid container spacing={1} direction="row">
                    {/* <Grid item xs={12} sm={8}>
                        <Typography variant="h5" mb={1} sx={{ fontWeight: "bold", position: "relative" }}>
                            Chat with Neti Buddy
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Typography variant="h6" sx={{fontWeight:"bold"}}>Reference system prompt</Typography>
                                           <Grid item xs={12} sm={8}>
                        {"You are a helpful, inquisitive friendly assistant that helps people plan their work priorities. You think in terms of priorities and not tasks. If a question is out of context, feel free to answer based on your knowledge about how to prioritize at work. Ask questions about the role and answer accordingly."}
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h6" sx={{fontWeight:"bold"}}>Existing system prompt</Typography>
                        {systemPrompt}
                    </Grid>
                    <Grid item lg={12} xs={12} sm={12} md={12} style={{position:'relative', paddingBottom:'32px'}}>
                                        <Typography variant="h6" sx={{fontWeight:"bold"}}>Set new system prompt</Typography>
                                        <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                                            <div ref={chatDisplayRef} />
                                            <TextField
                                                fullWidth
                                                label="Type here to set system prompt"
                                                variant="outlined"
                                                value={promptInput}
                                                onChange={(e) => setPromptInput(e.target.value)}
                                                onKeyDown={handlePromptTextChange}
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                endIcon={<SendIcon />}
                                                style={{ marginLeft: '10px' }}
                                                onClick={handleUpdatePrompt}
                                            >
                                                Send
                                            </Button>
                                        </div>
                                    </Grid> */}
                    <Grid item xs={12} >
                            {/* <Paper elevation={0} style={{ padding: '0px', display: 'flex', height: '70vh', position:'relative' }}> */}
                                <Grid container direction='row' spacing={2} style={{ padding: '0px', display: 'flex', height: '70vh', position:'relative' }} >
                                    <Grid item xs={12} minHeight='60vh'>
                                        <div className="MessageList"
                                        // style={{
                                        //     height: '400px', // Adjust this to set the maximum height of the chat container 
                                        //     // maxHeight: '100%', 
                                        //     overflowY: 'scroll',
                                        //     marginBottom: '10px',
                                        //     scrollbarColor: 'black',
                                        //     // width: '600px' ,
                                        //     position: 'relative',
                                        // }}

                                        >
                                            {chatSession &&
                                                (<List>
                                                    {chatSession.messages.map((message, index) => (
                                                        <ListItem key={index} style={{ display: 'flex', justifyContent: flexMapping[message.sender === 'Neti' ? 0 : 1] }}>
                                                            <Grid flexDirection='row-reverse'>
                                                                <Paper elevation={3} sx={{ padding: '5px', background: bgColorMapping[message.sender === 'Neti' ? 0 : 1] }}>
                                                                    <ListItemText primary={message.content} secondary={message.timestamp} />
                                                                </Paper>
                                                            </Grid>
                                                        </ListItem>
                                                    ))}
                                                </List>)}
                                        </div>
                                    </Grid>
                                    <Grid item lg={12} xs={12} sm={12} md={12} style={{position:'relative', paddingBottom:'32px'}}>
                                        <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                                            <div ref={chatDisplayRef} />
                                            <TextField
                                                fullWidth
                                                label="Type here to ask the buddy"
                                                variant="outlined"
                                                value={messageInput}
                                                onChange={(e) => setMessageInput(e.target.value)}
                                                onKeyDown={handleTextChange}
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                endIcon={<SendIcon />}
                                                style={{ marginLeft: '10px' }}
                                                onClick={handleSendMessage}
                                            >
                                                Send
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            {/* </Paper> */}
                    </Grid>
                </Grid>
                
            </Box>
            
        </RecoilRoot>
    );

};

export default NetiBuddyPage;

