import moment from "moment";
import { ListPriorityModel, yourPrioritiesListAtom } from "../recoil/priority";
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { addBuzzer } from "../api/buzzer";
import UseWebocketConnection from "../utils/WebsocketUtils";
import usePriorityApi from "../hooks/UsePriorityApi";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WS_BASE_LINK } from "../api/main";
import { ClosingPriorityModel } from "../recoil/priority";
import { currentSelectedPriorityAtom } from "../recoil/priority";
import { useRecoilState, useRecoilValue } from "recoil";

// const LABELS_COLORS = ["#f50057", "#2979ff", "#d500f9", "#ff9100", "#651fff", "#4caf50"];
const LABELS_COLORS = ["#c4c2f2", "#c2f2d4", "#f2c2e0", "#f2f1c2", "#c2c4f2", "gray"]

export const getColorFromTime = (priority: ListPriorityModel): string => {
    const createdDate = moment(priority.creation_time);
    const targetDate = moment(priority.target_date);
    const currentDate = moment();

    // 0 indicates createdDate
    // 100 indicates targetDate
    const percentile = 100.0 * (currentDate.diff(createdDate) / targetDate.diff(createdDate));

    // return priority.state !== "Active" || percentile >= 100 ? "#ff1744" : percentile > 75 ? "#ffc000" : "#4caf50";
    return priority.state !== "Active" || percentile >= 100 ? "#f2c2cb" : percentile > 75 ? "#f2e6c2" : "#c2f2c3";
};

export const getLabelColorForIndex = (index: number): string => {
    return LABELS_COLORS[index % LABELS_COLORS.length] ?? "#808080";
};

export const getUsersToNotify = (priority: ListPriorityModel) => {
    var target_users: Array<string> = [];

    if (priority.source_priority) {
        const collab_users: Array<string> = priority.source_priority.usertags.filter((usertag) => usertag.target_user!==priority.creator).map((usertag) => {return usertag.target_user})
        const creator: string = priority.source_priority.creator
        target_users = collab_users?collab_users.concat(creator):target_users.concat(creator)
    }
    else {
        target_users = priority.usertags.map((usertag) => {return usertag.target_user})
    }
    console.log("target_users:", target_users)

    return target_users
};

const usePriorityUtils = () => {


    const socket = new W3CWebSocket(WS_BASE_LINK);
    const [client, setClient] = useState(socket);

    const [alertSeverity, setAlertSeverity] = useState('success');

    const [alertText, setAlertText] = useState({'success': 'Buzzed successfully!',
    'warning': 'Error in submitting!', 'info': 'Priority moved to Bucket list', 'error':'error in the operation'})

    const [alertOpen, setAlertOpen] = useState(false);

    const {sendNotifications} = UseWebocketConnection();

    const {closePriority, toggleProgress, moveToYourPriorities,moveToBucketListPriorities,} = usePriorityApi();

    const yourPriorities = useRecoilValue(yourPrioritiesListAtom);

    const [closedPriority, setClosedPriority] = React.useState<ListPriorityModel | null>(null);

    // const [currentSelectedPriority, setCurrentSelectedPriority] = React.useState<ListPriorityModel | null>(null);
    const [currentSelectedPriority, setCurrentSelectedPriority] = useRecoilState(currentSelectedPriorityAtom);

    const onSuccess = (target_user: string) => {
        const target_users = [target_user];
        console.log("target_users is:", target_users)
        sendNotifications(client, target_users);
    };

    const onSuccess2 = (target_users: Array<string | undefined >) => {
        console.log("target_users is:", target_users)
        sendNotifications(client, target_users);
    };

    const onPlayPauseClick = async (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => {
        setAlertText({'success': 'Priority Resumed!', 'warning':'Priority Paused', 'info':'info', 'error':'error'});
        priority.state==='Paused' ? setAlertSeverity('success') : setAlertSeverity('warning');   
        setAlertOpen(true);
        console.log("Toggle action was called with priority:", priority);
        event.stopPropagation();

        const target_users = getUsersToNotify(priority)
        toggleProgress(priority, onSuccess2, target_users);
    };

    const onBuzzerClick = async (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => {
        setAlertText({'success': 'Buzzer sent!', 'warning':'error', 'info':'info', 'error':'error'});
        setAlertSeverity('success');
        setAlertOpen(true);
        event.stopPropagation();
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        console.log("Buzzer clicked by user: ", currentUserId);
        console.log("Buzzer clicked for the priority: ", JSON.stringify(priority));

        const target_users = getUsersToNotify(priority);
        addBuzzer(token, priority.id, currentUserId, target_users, onSuccess2);

    };

    const onTaskDoneClick = async (event: React.MouseEvent<HTMLButtonElement>, priority: ListPriorityModel) => {
        setAlertText({'success': 'Priority closed successfully', 'warning':'error', 'info':'Priority closed successfully', 'error':'error'});
        setAlertSeverity('info');
        event.stopPropagation();
        setClosedPriority(priority);
        console.log(priority);
    };

    const onClosePriority = () => {
        setClosedPriority(null);
        setCurrentSelectedPriority(null);
    }

    const onClosePrioritySuccess = (priority: ListPriorityModel) => {
        onClosePriority()
        const target_users = getUsersToNotify(priority)
        onSuccess2(target_users);
    }

    const [reasonToClose, setReasonToClose] = React.useState<string>("None");
    const [comments, setComments] = React.useState<string>("");

    const onClosePriorityClick = async (event: React.FormEvent<HTMLButtonElement>, priority: ListPriorityModel) => {
        event.preventDefault();
        setAlertOpen(true);
        const formData: ClosingPriorityModel = {
            priorityId: priority.id,
            reasonToClose: reasonToClose,
            additionalComments: comments,
        };
        closePriority(formData, onClosePrioritySuccess, priority);
    };

    const onMoveToYourPrioritiesClick = (_: React.MouseEvent, priority: ListPriorityModel) => {
        setAlertText({'success': 'Null','warning':'Main list already has 5 priorities', 'info':'Priority moved to Main list', 'error': 'Main list already has 5 priorities'});
        yourPriorities.length===5 ? setAlertSeverity('error') : setAlertSeverity('info');
        setAlertOpen(true); 

        console.log("data:", priority)
        const target_users = getUsersToNotify(priority);
        moveToYourPriorities(priority, onSuccess2, target_users);
        setCurrentSelectedPriority(null);
    };

    const onMoveToBucketListClick = async (_: React.MouseEvent, priority: ListPriorityModel) => {
        setAlertText({'success': 'Null','warning':'Null', 'info':'Priority moved to bucket list', 'error':'error'});
        setAlertSeverity('info');
        setAlertOpen(true); 

        const target_users = getUsersToNotify(priority);
        moveToBucketListPriorities(priority, onSuccess2, target_users);
        setCurrentSelectedPriority(null);

    };

    const onDragAndDropSuccess = (target_user) => {
        const target_users = [target_user];
        console.log("target_users is:", target_users)
        sendNotifications(client, target_users);
    };

    const [searchTerm, setSearchTerm] = useState("")


    return {
        onPlayPauseClick,
        onBuzzerClick,
        onMoveToBucketListClick,
        onMoveToYourPrioritiesClick,
        onTaskDoneClick,
        onClosePriorityClick,
        onDragAndDropSuccess,
        alertSeverity,
        setAlertSeverity,
        alertOpen,
        setAlertOpen,
        alertText,
        onSuccess,
        client,
        setClient,
        currentSelectedPriority,
        setCurrentSelectedPriority,
        closedPriority,
        setClosedPriority,
        reasonToClose,
        setReasonToClose,
        comments,
        setComments,
        onClosePriority,
        searchTerm,
        setSearchTerm
        
    }

};

export default usePriorityUtils;