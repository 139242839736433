import { Snackbar, Alert } from "@mui/material";

function AlertComponenet(props) {
  const { open, handleClose, severity, alertText } = props;
  return (
    <div>
      {" "}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {alertText[severity]}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertComponenet;