import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const data = [
    {
      name: 'January',
      Delivery: 3,
      BusinessDevelopment: 1,
      SelfDevelopment: 1,
      Others: 0
    },
    {
      name: 'February',
      Delivery: 2,
      BusinessDevelopment: 0,
      SelfDevelopment: 2,
      Others: 1
    },
    {
      name: 'March',
      Delivery: 3,
      BusinessDevelopment: 0,
      SelfDevelopment: 2,
      Others: 0
    },
    {
      name: 'April',
      Delivery: 4,
      BusinessDevelopment: 0,
      SelfDevelopment: 1,
      Others: 0
    },
    {
      name: 'May',
      Delivery: 4,
      BusinessDevelopment: 0,
      SelfDevelopment: 1,
      Others: 0
    },
    {
      name: 'June',
      Delivery: 3,
      BusinessDevelopment: 1,
      SelfDevelopment: 0,
      Others: 0
    },
    {
      name: 'July',
      Delivery: 3,
      BusinessDevelopment: 1,
      SelfDevelopment: 0,
      Others: 1
    },
    {
        name: 'August',
        Delivery: 2,
        BusinessDevelopment: 0,
        SelfDevelopment: 2,
        Others: 1
      },
      {
        name: 'September',
        Delivery: 2,
        BusinessDevelopment: 0,
        SelfDevelopment: 2,
        Others: 1
      },
  ];


const MyResponsiveLine = (
    // { data /* see data tab */ }
    ) => (
    <ResponsiveContainer width="100%" height="100%">
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="Delivery" stroke="#8884d8" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="BusinessDevelopment" stroke="#82ca9d" />
      <Line type="monotone" dataKey="SelfDevelopment" stroke="#C981AF" />
      <Line type="monotone" dataKey="Others" stroke="#c9c880" />
      {/* <Line type="monotone" dataKey="Delivery" stroke="#c4c2f2" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="BusinessDevelopment" stroke="#c2f2d4" />
      <Line type="monotone" dataKey="SelfDevelopment" stroke="#f2c2e0" />
      <Line type="monotone" dataKey="Others" stroke="#f2f1c2" /> */}
    </LineChart>
  </ResponsiveContainer>
)

export default MyResponsiveLine;