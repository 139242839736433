import { atom, useRecoilState } from "recoil";
import loggedInUserAtom from "../recoil/user/atom";


const useLoggedInUser = () => {
    const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserAtom);
    return {
        loggedInUser,
        setLoggedInUser,
    };
};

export default useLoggedInUser;
