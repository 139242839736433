interface MentionOutput {
    content: string;
    html: string;
}

export const emptyMentionOutput: MentionOutput = {
    content: "",
    html: "",
};

export default MentionOutput;
