import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, MenuItem, Select, Stack, TextField } from "@mui/material";
import FormInputItemHeader from "./FormInputItemHeader";
import { VerticalSpacer } from "./Spacer";
import { ListPriorityModel } from "../recoil/priority";
import usePriorityUtils from "../utils/PriorityUtils";

const ADDITIONAL_COMMENTS_MAX_LENGTH = 200;

interface ClosePriorityDialogProps {
    priority: ListPriorityModel;
}

const ClosePriorityDialog: React.FC<ClosePriorityDialogProps> = (props) => {
    const { priority} = props;

    const {onClosePriorityClick, reasonToClose, setReasonToClose, comments, setComments, onClosePriority} = usePriorityUtils();

    return (
        <Dialog open fullWidth>
            <DialogTitle>
                <Stack direction={"row"}>
                    <Typography variant="h6" flex={"display"} flexGrow={1}>
                        Close Priority
                    </Typography>
                    <IconButton onClick={onClosePriority}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h6">{priority.title}</Typography>
                <VerticalSpacer height={24} />

                <ReasonToClose value={reasonToClose} onChange={setReasonToClose} />
                <VerticalSpacer height={24} />

                <FormInputItemHeader label="Additional Comments" />
                <TextField
                    multiline
                    rows={4}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: ADDITIONAL_COMMENTS_MAX_LENGTH }}
                    helperText={comments.length > 0 ? `${comments.length}/${ADDITIONAL_COMMENTS_MAX_LENGTH}` : ""}
                />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button variant="contained" onClick={(e) => {onClosePriorityClick(e, priority)}} sx={{ marginY: 1 }}>
                    Close Priority
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface ReasonToCloseProps {
    value: string;
    onChange: (newValue: string) => void;
}

const ReasonToClose: React.FC<ReasonToCloseProps> = ({ value, onChange }) => {
    const reasonsToClose = ["None", "Completed", "Invalid", "Redundant"];

    return (
        <Stack direction={"row"} alignItems={"center"}>
            <FormInputItemHeader label="Choose reason to Close" />
            <FormControl sx={{ mx: 2 }} size="small">
                <Select value={value} onChange={(e) => onChange(e.target.value)}>
                    {reasonsToClose.map((reason) => (
                        <MenuItem key={reason} value={reason}>
                            {reason}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};

export default ClosePriorityDialog;
