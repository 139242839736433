import { useRecoilState, useRecoilValue } from "recoil";
import { Auth } from "aws-amplify";
import { getAllNotifications, getUnreadNotifications, putNotificationSeen } from "../api/notifications";
import { notificationsAtom, allNotificationsAtom } from "../recoil/notification";
import { getChatMessages, getSystemPrompt, postChatMessage, postSystemPrompt } from "../api/buddy";
import { yourChatsAtom } from "../recoil/chat";
import { MessageModel, chatSessionModel } from "../recoil/chat";
import { itemByIdSelector } from "../recoil/chat/selector";
import { systemPromptAtom } from "../recoil/chat/atom";



const useChatApi = () => {

    const [chatSessions, setChatSessions] = useRecoilState(yourChatsAtom);
    const chatSession = useRecoilValue(itemByIdSelector(0));
    const [systemPrompt, setSystemPrompt] = useRecoilState(systemPromptAtom);
    console.log()
    const updateChatSessions = async (messages: MessageModel[]) => {

        // Make sure not to mutate any objects when returning the new object state: 
        let updatedSessions = [...chatSessions].map((item) => {

            console.log('item:', item);

            console.log('chatSession active:', chatSession)

            if (chatSession && (item.sessionId === chatSession.sessionId)) {
                var newMessages = [...item.messages, messages[0]]
                for (let i=1; i < messages.length; i++) {
                    newMessages = [...newMessages, messages[i]]
                }
                console.log('newMessages:', newMessages)
                const itemNew:chatSessionModel = {...item, messages: newMessages } as chatSessionModel
                console.log('item after:', itemNew);
                return itemNew;
            }
            else return item;
        });

        console.log('preliminary updated sessions:', updatedSessions);
        setChatSessions(updatedSessions);
        console.log('final updated sessions:', chatSessions);

        return true
    };

    const sendChatMessage = async (messageInput) => {
        console.log("Posting chat messages from API");
        const currentUser = await Auth.currentAuthenticatedUser();
        var currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        currentUserId = currentUserId.replaceAll(".", "DOT")
        currentUserId = currentUserId.replaceAll("@", "ATTHERATE")
        const inMessage = {
            sender: currentUserId,
            content: messageInput,
            timestamp: new Date().toISOString().split('.')[0]+"Z"
        }
        updateChatSessions([inMessage])
        console.log("inMessage:", inMessage);
        console.log('firstUpdatedChat', chatSessions)
        postChatMessage(token, currentUserId, "0", inMessage, chatSession?.messages.slice(-2,), updateChatSessions); 
        console.log('udpatedChat in', chatSessions);
        return true
    };

    const fetchChatMessages = async() => {
        console.log("Fetching chat messages from API");
        const currentUser = await Auth.currentAuthenticatedUser();
        var currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        currentUserId = currentUserId.replaceAll(".", "DOT")
        currentUserId = currentUserId.replaceAll("@", "ATTHERATE")
        getChatMessages(token, currentUserId, "0", setChatSessions, updateChatSessions)
    };

    const fetchSystemPrompt = async() => {
        const currentUser = await Auth.currentAuthenticatedUser();
        var currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        getSystemPrompt(token, setSystemPrompt)
    };

    const updateSystemPrompt = async(system_prompt) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        var currentUserId = currentUser.attributes["email"];
        const token = currentUser.signInUserSession.idToken.jwtToken;
        postSystemPrompt(token, system_prompt)
    };

    return {
        fetchChatMessages,
        sendChatMessage,
        fetchSystemPrompt,
        updateSystemPrompt,
    };
};

export default useChatApi;
