import { z } from "zod";

const TopicMapModelSchema = z.object({
    topic: z.number(),
    tenant: z.number(),
});

const UserTagMapModelSchema = z.object({
    user: z.string().email(),
    tenant: z.number(),
});

const PriorityModelSchema = z.object({
    id: z.number(),
    creator: z.string().email(),
    creation_time: z.string(),
    title: z
        .string()
        .min(1, "Title cannot be empty")
        .min(5, "Title is too small")
        .max(150, "Title cannot be longer than 150 characters"),
    description: z.string().max(600, "Description cannot be longer than 600 characters"),
    target_date: z
        .date()
        .nullable()
        .refine((d) => d != null, { message: "Date cannot be empty" }),
    priority_order: z.number(),
    state: z.string(),
    tenant: z.number(),
    close_reason: z.string(),
    close_comment: z.string(),
    priority_type: z.string()
});

const RequestStatusSchema = z.object({
    id: z.number(),
    target_user: z.string().email(),
    request_status: z.string(),
    target_priority: PriorityModelSchema
});

const ListPriorityBaseModelSchema = z.object({
    id: z.number(),
    creator: z.string().email(),
    creation_time: z.string(),
    title: z
        .string()
        .min(1, "Title cannot be empty")
        .min(5, "Title is too small")
        .max(150, "Title cannot be longer than 150 characters"),
    description: z.string().max(600, "Description cannot be longer than 600 characters"),
    target_date: z
        .date()
        .nullable()
        .refine((d) => d != null, { message: "Date cannot be empty" }),
    priority_order: z.number(),
    state: z.string(),
    tenant: z.number(),
    close_reason: z.string(),
    close_comment: z.string(),
    priority_type: z.string(),
    priority_scope: z.string(),
    level_of_effort: z.string(),
    topics: z.array(TopicMapModelSchema),
    usertags: z.array(RequestStatusSchema),
});

const ListPriorityModelSchema = z.object({
    id: z.number(),
    creator: z.string().email(),
    creation_time: z.string(),
    title: z
        .string()
        .min(1, "Title cannot be empty")
        .min(5, "Title is too small")
        .max(150, "Title cannot be longer than 150 characters"),
    description: z.string().max(600, "Description cannot be longer than 600 characters"),
    target_date: z
        .date()
        .nullable()
        .refine((d) => d != null, { message: "Date cannot be empty" }),
    priority_order: z.number(),
    state: z.string(),
    tenant: z.number(),
    close_reason: z.string(),
    close_comment: z.string(),
    priority_type: z.string(),
    priority_scope: z.string(),
    level_of_effort: z.string(),
    topics: z.array(TopicMapModelSchema),
    usertags: z.array(RequestStatusSchema),
    source_priority: ListPriorityBaseModelSchema
});

const priorityRequestModelSchema = z.object({
    id: z.number(),
    originator_user: z.string(),
    priority: ListPriorityModelSchema,
});

const ListCalendarEventSchema = z.object({
    id: z.string(),
    title: z.string(),
    start: z.string(),
    end: z.string(),
    is_all_day: z.boolean(),
    tenant: z.number()
});

const commentModelSchema = z.object({
    id: z.number(),
    creator: z.string(),
    comment_text: z.string()
});

const priorityCommentsModelSchema = z.object({
    id: z.number(),
    comments: z.array(commentModelSchema),
});

export const createPriorityModelSchema = z.object({
    creator: z.string().email().optional(),
    creation_time: z.string().optional(),
    title: z
        .string()
        .min(1, "Title cannot be empty")
        .min(5, "Title is too small")
        .max(150, "Title cannot be longer than 150 characters"),
    description: z.string().max(600, "Description cannot be longer than 600 characters"),
    target_date: z
        .date()
        .nullable()
        .refine((d) => d != null, { message: "Date cannot be empty" }),
    state: z.string(),
    tenant: z.number(),
    priority_type: z.string().min(1, "Type should be selected"),
    priority_scope: z.string().min(1, "Scope should be selected"),
    level_of_effort: z.string().min(1, "Level of effort should be selected"),
    topics: z.array(TopicMapModelSchema).min(1, "Labels cannot be empty").max(5, "Labels cannot be more than 5"),
    usertags: z.string().array(),
});

export const closingPrioritySchema = z.object({
    priorityId: z.number(),
    reasonToClose: z.string().min(1, "Closing reason cannot be empty"),
    additionalComments: z.string().min(1, "Comments cannot be empty"),
});

export type ListPriorityBaseModel = z.infer<typeof ListPriorityBaseModelSchema>;
export type ListPriorityModel = z.infer<typeof ListPriorityModelSchema>;
export type ListCalendarEventModel = z.infer<typeof ListCalendarEventSchema>;
export type UserTagMapModel = z.infer<typeof UserTagMapModelSchema>;
export type TopicMapModel = z.infer<typeof TopicMapModelSchema>;
export type RequestStatusModel = z.infer<typeof RequestStatusSchema>;
export type PriorityRequestModel = z.infer<typeof priorityRequestModelSchema>;
export type PriorityCommentsModel = z.infer<typeof priorityCommentsModelSchema>;
export type CreatePriorityModel = z.infer<typeof createPriorityModelSchema>;
export type ClosingPriorityModel = z.infer<typeof closingPrioritySchema>;