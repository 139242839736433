import { Box } from "@mui/material";
import React from "react";

interface MentionItemProps extends React.ComponentPropsWithoutRef<"div"> {
    isActive: boolean;
}

const MentionItem = ({ isActive, style, ...props }: MentionItemProps) => {
    const ref = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        console.log("useEffect from MentionItem.tsx");
        if (isActive) {
            ref.current?.scrollIntoView({ block: "nearest" });
        }
    }, [isActive]);

    return (
        <Box
            ref={ref}
            sx={{
                padding: "8px 12px",
                cursor: "pointer",
            }}
            style={{
                backgroundColor: isActive ? "lightgrey" : undefined,
                ...style,
            }}
            {...props}
        />
    );
};

export default MentionItem;
