import SearchIcon from "@mui/icons-material/Search";
import { alpha, InputBase, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import usePriorityUtils from "../../utils/PriorityUtils";
import usePriorityApi from "../../hooks/UsePriorityApi";
import { useSetRecoilState } from "recoil";
import { searchPrioritiesAtom } from "../../recoil/priority";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "200px",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

interface SearchBarProps {
    // priorityId: number;
}

const SearchBar = (props: SearchBarProps) => {

    const navigate = useNavigate();

    const {searchTerm, setSearchTerm} = usePriorityUtils();

    const { fetchPrioritiesByKeyword } = usePriorityApi();

    const setSearchPriorities = useSetRecoilState(searchPrioritiesAtom);

    const handleKeydown = (e) => {
        if (e.key === 'Enter')
        {
            if (searchTerm.length > 1) {
            console.log("search term is:", searchTerm)
            setSearchPriorities([])
            fetchPrioritiesByKeyword(searchTerm)
            navigate('?term='+searchTerm.valueOf())
        }
        else {
            console.log("search term is:", searchTerm)
            // setSearchTerm("")
            setSearchPriorities([])
            navigate('./search')
        }
    }
    };

    const handleSearchInput = (e) => {
        if (e.key !== 'Enter') {
            setSearchTerm(e.target.value)
        }  
    };

 
    return (
        <Search sx={{ display: { xs: "flex", md: "flex" } }}>
        <SearchIconWrapper>
            <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase placeholder="Search…" onChange = {(e) => handleSearchInput(e)} onKeyDown={(e) => handleKeydown(e)}/>
        </Search>
    );
};

export default SearchBar;