import { atom } from "recoil";
import {LoggedInUserModel, UserTagModel} from "./model";

const loggedInUserAtom = atom<LoggedInUserModel>({
    key: "loggedInUserAtom",
    default: {
        userId: "",
        username: "",
        email_id: "",
    },
});

export const userTagsAtom = atom<UserTagModel[]>({
    key: "userTagsAtom",
    default: [
        { id: "ganesh.reddy.22@gmail.com", label: "Ganesh Personal", tenant: 1 },
        { id: "ganesh.ravi.8020@gmail.com", label: "Ganesh Neti", tenant: 1 },
    ],
});

export default loggedInUserAtom;