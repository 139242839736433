import { atom } from "recoil";

export const sideNavBarSelectedItemIndexAtom = atom<number>({
    key: 'sideNavBarSelectedItemIndexAtom',
    default: 1
});

export const sideNavBarMobileOpenAtom = atom<boolean>({
    key: 'sideNavMobileOpenAtom',
    default: false
});

export const sideNavBarOpenAtom = atom<boolean>({
    key: 'sideNavOpenAtom',
    default: true
});

export const navBarMobileMoreAnchorElAtom = atom<HTMLElement | null>({
    key: 'navBarMobileMoreAnchorElAtom',
    default: null
});

export const navBarAccountAnchorElAtom = atom<HTMLElement | null>({
    key: 'navBarAccountAnchorElAtom',
    default: null
});