import React, { useEffect, useState } from "react";
import usePriorityApi from "../hooks/UsePriorityApi";
import Typography from "@mui/material/Typography";
import { Box, Card, Grid, IconButton, Menu, MenuItem, Stack, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CreatePriorityModel, ListPriorityModel, priorityRequestsAtom, yourPrioritiesListAtom } from "../recoil/priority";
import moment from "moment";
import { PriorityRequestModel } from "../recoil/priority";
import UseWebocketConnection from "../utils/WebsocketUtils";
import AlertComponent from "./AlertComponent";
import PriorityRequestDetail from "./PriorityRequestDetail";
import { useRecoilValue } from "recoil";

interface PriorityRequestsProps {
    selectedPriorityId: number,
    client: any;
}

function PriorityRequests(props: PriorityRequestsProps) {
    const { fetchYourPriorities, fetchBucketListPriorities } =
        usePriorityApi();

    const priorityRequests = useRecoilValue(priorityRequestsAtom);
    const yourPriorities = useRecoilValue(yourPrioritiesListAtom);

    const { createPriority, modifyPriorityRequestStatus } = usePriorityApi();

    const [currentSelectedRequest, setCurrentSelectedRequest] = React.useState<PriorityRequestModel | undefined>(undefined);
    const [closedPriority, setClosedPriority] = React.useState<ListPriorityModel | null>(null);

    const theme = useTheme();
    const isSizeSmallOrAbove = useMediaQuery(theme.breakpoints.up("sm"));

    const {sendNotifications} = UseWebocketConnection();
    
    const [alertOpen, setAlertOpen] = useState(false);

    const [alertText, setAlertText] = useState({'success': 'Priority request accepted!',
    'warning': 'Priority request rejected', 'info': 'Priority request moved to Bucket list',
    'error':'Maximum number of main priorities reached'})

    const [alertSeverity, setAlertSeverity] = useState('success');

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setAlertOpen(false);
      };

    useEffect(() => {
        console.log("useEffect from PriorityRequests.tsx");
        if (props.selectedPriorityId !== currentSelectedRequest?.priority.id) {
            // const _selectedPriority = priorityRequests.find((r) => r.priority.id == props.selectedPriorityId);
            props.selectedPriorityId && onRequestSelect(props.selectedPriorityId);
        };
        // console.log(priorityRequests);
    }, [props, priorityRequests]);

    const onRequestSelect = (priorityId: number) => {
        if(currentSelectedRequest?.priority.id!==priorityId){
            // console.log(requestId);
            // console.log(priorityRequests);
            const selectedRequest = priorityRequests.filter((request) => request.priority.id===priorityId)[0]
            // console.log(selectedRequest);
            setCurrentSelectedRequest(selectedRequest);
        };
    };

    const onAcceptClick = (event: React.MouseEvent, priorityRequest: PriorityRequestModel) => {
        event.stopPropagation();
        (yourPriorities.length === 5) ? setAlertSeverity('error'): setAlertSeverity('success');
        setAlertOpen(true);
        const priority: CreatePriorityModel = {
            // creator: ,
            title: priorityRequest.priority.title,
            description: priorityRequest.priority.description,
            target_date: priorityRequest.priority.target_date,
            state: "Active",
            tenant: priorityRequest.priority.tenant,
            priority_type: "Main Priority",
            priority_scope: "Public",
            level_of_effort: "Low",
            topics: priorityRequest.priority.topics,
            usertags: [],
        };

        if (yourPriorities.length < 5) {
            
            const request: any = {
                id: priorityRequest.id,
                request_status: "Accepted",
                target_priority: null,
                status_comment: "",
            };

            const onSuccess = (data: any) => {
                console.log("priority id after acceptance is:", data.id);
                request.target_priority = data.id
                modifyPriorityRequestStatus(request);
                fetchYourPriorities(() => {});
                const users = new Array();
                users[0] = priorityRequest.priority.creator;
                sendNotifications(props.client, users);
                console.log("accepted priority", data);
            }

            createPriority(priority, onSuccess);
        } else {
            console.log("reduce one main priority before accepting this new priority!");
        }
    };

    const onRejectClick = (event: React.MouseEvent, priorityRequest: PriorityRequestModel) => {
        event.stopPropagation();
        setAlertSeverity('warning');
        setAlertOpen(true);
        const request: any = {
            id: priorityRequest.id,
            request_status: "Rejected",
            status_comment: "",
        };
        modifyPriorityRequestStatus(request);
        const users = new Array();
        users[0] = priorityRequest.priority.creator;
        sendNotifications(props.client, users);
    };

    const onMoreInfoClick = (event: React.MouseEvent, priorityRequest: PriorityRequestModel) => {
        event.stopPropagation();
    };

    const onMoveToBucketListClick = (_: React.MouseEvent, priorityRequest: PriorityRequestModel) => {
        setAlertSeverity('info');
        setAlertOpen(true);
        const priority: CreatePriorityModel = {
            title: priorityRequest.priority.title,
            description: priorityRequest.priority.description,
            target_date: priorityRequest.priority.target_date,
            state: "Active",
            tenant: priorityRequest.priority.tenant,
            priority_type: "Bucket List",
            priority_scope: "Public",
            level_of_effort: "Low",
            topics: priorityRequest.priority.topics,
            usertags: [],
        };

        

        const request: any = {
            id: priorityRequest.id,
            request_status: "Bucket List",
            status_comment: "",
        };

        const onSuccess = (data: any) => {
            console.log("priority id after moving to bucket list is:", data.id);
            request.target_priority = data.id
            modifyPriorityRequestStatus(request);
            const users = new Array();
            users[0] = priorityRequest.priority.creator;
            fetchBucketListPriorities();
            sendNotifications(props.client, users);
            console.log("bucketed priority", data);
        }

        createPriority(priority, onSuccess);
        

        console.log("moved to bucket list priority", priority);
    };

    return (
        priorityRequests.length === 0 ? <><p>No new requests</p></> :
        <>
            <Box px={{ sm: 2, md: 4 }} py={4}>
                {priorityRequests.map((priorityRequest) => {
                    return (
                        <Grid
                            key={priorityRequest.priority.title}
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item md={8} xs={12} sx={{ padding: "0!important" }}>
                                <Card
                                    key={priorityRequest.priority.title}
                                    onClick={() => onRequestSelect(priorityRequest.priority.id)}
                                    sx={{ marginBottom: "12px", display: "flex" }}
                                >
                                    <Stack
                                        direction={"column"}
                                        flexGrow={1}
                                        sx={{
                                            padding: "10px 20px",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            align="left"
                                            sx={{
                                                flexGrow: 1,
                                                margin: "0 2",
                                            }}
                                        >
                                            Priority Request from {priorityRequest.originator_user}
                                        </Typography>
                                        <Stack justifyContent="space-around" direction={"row"} alignItems={"center"}>
                                            <Typography
                                                variant="h6"
                                                align="left"
                                                sx={{
                                                    width: "min-content",
                                                    flexGrow: 1,
                                                    margin: "0 2",
                                                }}
                                            >
                                                {priorityRequest.priority.title}
                                            </Typography>
                                            {isSizeSmallOrAbove && (
                                                <Stack direction={"column"} ml={2} alignItems={"center"}>
                                                    <PriorityDate priority={priorityRequest.priority} />
                                                    <PriorityActions
                                                        priorityRequest={priorityRequest}
                                                        onAcceptClick={onAcceptClick}
                                                        onRejectClick={onRejectClick}
                                                        onMoreInfoClick={onMoreInfoClick}
                                                        onMoveToBucketListClick={onMoveToBucketListClick}
                                                    />
                                                </Stack>
                                            )}
                                        </Stack>
                                        {!isSizeSmallOrAbove && (
                                            <Stack direction={"column"} ml={2} mt={2} alignItems={"center"}>
                                                <PriorityDate priority={priorityRequest.priority} />
                                                <PriorityActions
                                                    priorityRequest={priorityRequest}
                                                    onAcceptClick={onAcceptClick}
                                                    onRejectClick={onRejectClick}
                                                    onMoreInfoClick={onMoreInfoClick}
                                                    onMoveToBucketListClick={onMoveToBucketListClick}
                                                />
                                            </Stack>
                                        )}
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item md={4} xs={12} />
                        </Grid>
                    );
                })}
                {currentSelectedRequest && (
                    <PriorityRequestDetail priorityRequest={currentSelectedRequest} 
                            onCloseDialog={()=>setCurrentSelectedRequest(undefined)}
                            PriorityDate={PriorityDate}
                            PriorityActions={PriorityActions}
                            onAcceptClick={onAcceptClick}
                            onRejectClick={onRejectClick}
                            onMoreInfoClick={onMoreInfoClick}
                            onMoveToBucketListClick={onMoveToBucketListClick}
                            />
                )}
            </Box>
            <AlertComponent open={alertOpen} handleClose={handleAlertClose} severity={alertSeverity} alertText={alertText}/>
        </>
    );
}

interface PriorityDateProps {
    priority: ListPriorityModel;
}

const PriorityDate: React.FC<PriorityDateProps> = ({ priority }) => {
    const date = moment(priority.target_date);
    const formattedDate = date.format("MMM DD, YYYY");
    const relativeDate = date.fromNow();
    const dateUiText = `${formattedDate} (${relativeDate})`;

    return (
        <Typography
            noWrap
            sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: "gray",
            }}
        >
            {dateUiText}
        </Typography>
    );
};

interface PriorityActionsProps {
    priorityRequest: PriorityRequestModel;
    onAcceptClick: (event: React.MouseEvent, priority: PriorityRequestModel) => void;
    onRejectClick: (event: React.MouseEvent, priority: PriorityRequestModel) => void;
    onMoreInfoClick: (event: React.MouseEvent, priority: PriorityRequestModel) => void;
    onMoveToBucketListClick: (event: React.MouseEvent, priorityRequest: PriorityRequestModel) => void;
}

const PriorityActions: React.FC<PriorityActionsProps> = ({
    priorityRequest,
    onAcceptClick,
    onRejectClick,
    onMoreInfoClick,
    onMoveToBucketListClick,
}) => {
    const [moreAnchorElement, setMoreAnchorElement] = React.useState<null | HTMLElement>(null);
    const isMoreOpen = Boolean(moreAnchorElement);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMoreAnchorElement(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        setMoreAnchorElement(null);
    };

    const handleMoreItemClick = (event: React.MouseEvent, index: number) => {
        event.stopPropagation();
        if (index === 0) onMoveToBucketListClick(event, priorityRequest);
        handleClose(event);
    };

    return (
        <Stack direction={"row"}>
            <Tooltip title="Accept priority request">
            <IconButton onClick={(e) => onAcceptClick(e, priorityRequest)}>
                <CheckCircleIcon style={{ fontSize: 32, color: "green" }} />
            </IconButton>
            </Tooltip>
            <Tooltip title="Reject priority request">
            <IconButton onClick={(e) => onRejectClick(e, priorityRequest)}>
                <CancelIcon style={{ fontSize: 32, color: "red"}} />
            </IconButton>
            </Tooltip>
            <Tooltip title="Request more context">
            <IconButton onClick={(e) => onMoreInfoClick(e, priorityRequest)}>
                <InfoIcon style={{ fontSize: 32, color: "orange" }} />
            </IconButton>
            </Tooltip>
            <Tooltip title="More options">
            <IconButton onClick={handleClick}>
                <MoreVertIcon style={{ fontSize: 32 }} />
            </IconButton>
            </Tooltip>
            <Menu
                anchorEl={moreAnchorElement}
                open={isMoreOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem onClick={(e) => handleMoreItemClick(e, 0)}>Move to Bucket List</MenuItem>
            </Menu>
        </Stack>
    );
};

export default PriorityRequests;
