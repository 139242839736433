//export const API_BASE_LINK: string = "https://api.neti.work/"
//export const WS_BASE_LINK: string = "wss://etyxipfl71.execute-api.us-east-1.amazonaws.com/production"

export const API_BASE_LINK: string = "https://api.neti.ai/"
export const WS_BASE_LINK: string = "wss://1m85regi23.execute-api.us-east-1.amazonaws.com/production"
export const BUD_BASE_LINK: string = "https://bud.neti.ai/"
// export const BUD_BASE_LINK: string = "https://0bk382n16i.execute-api.us-east-1.amazonaws.com/dev/"

// For testing locally
// export const API_BASE_LINK: string = "http://localhost:8000/"
// export const BUD_BASE_LINK: string = "http://localhost:8000/"